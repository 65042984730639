import React from "react";
import ContactUs from "../../Components/ContactUs";

import Banner from "../../Parts/Company/Banner";
import Included from "../../Parts/Company/Included";
import NewPlans from "../../Parts/Residence/NewPlans";
import Prepare from "../../Parts/Company/Prepare";
import PhonePlan from "../../Components/PhonePlan";
import Phone from "../../Parts/Company/Phone";

import "./style.css";
import CustomCarousel from "../../Components/CustomCarousel";
import WWWIMG from "../../assets/img/Company/www.png";
import PhoneIMG from "../../assets/img/Company/phone.png";
import FileIMG from "../../assets/img/Company/transfer.png";
import ShieldIMG from "../../assets/img/Company/shield.png";
import TransferIMG from "../../assets/img/Company/file-transfer.png";

function Company() {
  const items = [
    {
      image: TransferIMG,
      description:
        "Garantia da velocidade de internet contratada e sem oscilações",
    },
    {
      image: PhoneIMG,
      description:
        "Suporte técnico exclusivo 24h, 7 dias por semana, o ano todo.",
    },
    {
      image: WWWIMG,
      description:
        "IP fixo para poder hospedar sites e fazer atendimento online.",
    },
    {
      image: FileIMG,
      description: "Envie e receba arquivos com a mesma velocidade.",
    },
    {
      image: ShieldIMG,
      description: "Monitoramento contínuo de qualidade.",
    },
  ];
  return (
    <div id="company-page" className="mt-5">
      <Banner />
      <Prepare />
      <NewPlans planTitle="Empresariais" />
      <Included />
      <PhonePlan />
      <Phone />
      <CustomCarousel
        items={items}
        sectionTitle=' <span class="c-primary">Internet Corporativa</span>'
      />
      <ContactUs
        title="Fale com um representante de vendas"
        subtitle={
          <p>
            Preencha o formulário abaixo e um de nossos representantes entrará
            em contato. Se você deseja falar com alguém imediatamente, acesse o
            <a href="https://api.whatsapp.com/send?phone=4137977777">
              {" "}
              WhatsApp 41 37977777.{" "}
            </a>
          </p>
        }
        link={
          "https://api.whatsapp.com/send?phone=4137977777&text=Planos%20Empresariais"
        }
      />
    </div>
  );
}

export default Company;
