import React, { useState } from "react";
import CustomModal from "../CustomModal";
import "./style.css";

export default function NewPlans({ planTitle = "" }) {
  const [showModal0, setShowModal0] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const plans = [
    {
      color: "green-plan",
      speed: "500 Megabits",
      value: "109,99",
      normalValue: "129,99",
      topics: [
        "Sem taxa de instalação*",
        "Suporte 24h todos os dias",
        "Internet rápida com 500 Megabits Downloads",
        "Ideal para Jogos Online",
        "Super Wi-Fi Onboard",
        "Internet com Tecnologia FHS (R)",
        "Telefone Infinity*",
        "Digital Books:",
        "Máquina de contos",
        "Conta outra vez",
        "Eulalia",
        "Vamolê",
      ],
    },
    {
      color: "blue-plan",
      speed: "600 Megabits",
      value: "119,99",
      normalValue: "139,99",
      topics: [
        "Sem taxa de instalação*",
        "Suporte 24h todos os dias",
        "Internet rápida com 600 Megabits Downloads",
        "Ideal para Jogos Online",
        "Super Wi-Fi Onboard",
        "Internet com Tecnologia FHS (R)",
        "Telefone Infinity*",
        "Digital Books:",
        "Máquina de contos",
        "Conta outra vez",
        "Eulalia",
        "Vamolê",
      ],
    },
    {
      color: "red-plan",
      speed: "1000 Megabits",
      value: "199,99",
      normalValue: "219,99",
      topics: [
        "Sem taxa de instalação*",
        "Suporte 24h todos os dias",
        "Internet Ultra rápida com 1000 Megabits Downloads",
        "Inacreditável para Jogos Online",
        "Super Wi-Fi Onboard",
        "Bom para dispositivos de última geração",
        "Telefone Infinity*",
        "Internet com Tecnologia FHS (R)",
        "Instalação Profissional",
        "Audio Books by Skeelo",
        "Digital Books:",
        "Máquina de contos",
        "Conta outra vez",
        "Eulalia",
        "Bebanca Livros",
        "Beeduca",
      ],
    },
  ];

  return (
    <section id="company-plans" className="bg-gray">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <h2 className="section-title text-center">
              Planos <span className="c-primary">{planTitle}</span>
            </h2>
          </div>
          <div className="row justify-content-center mt-5">
            {plans.map((plan, key) => (
              <div
                className={`col-md-6 col-lg-4 mb-4 ${
                  plans.length - 1 === key ? "mb-0" : ""
                }`}
                key={key}
              >
                <div className="card card-plan shadow-lg">
                  <div className="card-title">
                    <div className="d-flex flex-wrap justify-content-between align-items-top">
                      <div
                        className={`plan-header-speed text-white fw-bold fs-4 ${plan.color}`}
                      >
                        {plan.speed}
                      </div>
                      <div className="plan-header-value fs-6 p-3">
                        <span className="fs-6">
                          De: <del>{`${plan.normalValue}/ mês`}</del>
                        </span>
                        <div className="d-flex align-items-center">
                          <h2 className="fw-bold plan_value">
                            <span className="fs-6">Por: </span>
                            {plan.value}
                          </h2>
                          <span className="pt-4">/mês</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <ul className="plans-list overflow-x-hidden overflow-y-auto h-270px">
                      {plan.topics.map((topic, index) => (
                        <li key={index} className="plan-list">
                          {topic}
                        </li>
                      ))}
                    </ul>
                    <ul className="plans-list overflow-x-hidden overflow-y-auto">
                      <li className="font-italic">* Consulte condições</li>
                    </ul>
                  </div>
                  <div className="card-footer bg-white">
                    <div className="d-grid gap-3">
                      <button
                        className="btn btn-primary text-white p-3"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                        }}
                      >
                        Checar disponibilidade
                      </button>
                      {key === 0 && (
                        <div
                          className="row cursor-pointer"
                          onClick={() => setShowModal0(!showModal0)}
                        >
                          <div className="col-md-12 text-center mb-20">
                            <span className="fs-18 c-gray font-italic fw-600">
                              + detalhes dessa oferta
                            </span>
                          </div>
                        </div>
                      )}
                      {key === 1 && (
                        <div
                          className="row cursor-pointer"
                          onClick={() => setShowModal1(!showModal1)}
                        >
                          <div className="col-md-12 text-center mb-20">
                            <span className="fs-18 c-gray font-italic fw-600">
                              + detalhes dessa oferta
                            </span>
                          </div>
                        </div>
                      )}
                      {key === 2 && (
                        <div
                          className="row cursor-pointer"
                          onClick={() => setShowModal2(!showModal2)}
                        >
                          <div className="col-md-12 text-center mb-20">
                            <span className="fs-18 c-gray font-italic fw-600">
                              + detalhes dessa oferta
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {showModal0 ? (
                    <CustomModal
                      scrollable={true}
                      title="Detalhes da oferta"
                      setShowModal={setShowModal0}
                    >
                      <tr>
                        <td>
                          <div className="d-flex flex-column">
                            <span className="fs-20">
                              LOViZ Gigabit: 500 MEGA
                            </span>
                            <span>500 MEGA + Acesso a LOViZ BOOK</span>
                            <span>
                              Valor Mensal do Pacote: 129,99 / mês no Boleto ou
                              Débito em conta
                            </span>
                            <span>
                              Oferta Especial para pagamento até a Data do
                              Vencimento: 109,99 / mês no Boleto ou Débito em
                              conta
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Instalação Profissional
                            </span>
                            <span>
                              A oferta escolhida possui instalação profissional
                              de um técnico especializado da LOViZ.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Prazo de Instalação</span>
                            <span>
                              A instalação ocorre em até 7 dias úteis a partir
                              da data de agendamento. De Segunda a Sábado, de
                              08:00 às 20:00.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Equipamento</span>
                            <span>
                              O modem Super Wi-Fi da LOViZ será entregue em
                              comodato mediante solicitação. O cliente pode
                              optar por utilizar o próprio dispositivo.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Velocidade do Plano</span>
                            <span>
                              Essas são as velocidades máximas nominais. A
                              velocidade pode variar devido a fatores externos.
                              Promocionalmente, a franquia é ilimitada.
                              Velocidade contratada é a taxa de transmissão ou
                              velocidade da conexão. É o quão rápido um pacote
                              de dados do seu dispositivo vai ser levado até a
                              operadora e vice-versa. A velocidade do serviço de
                              Internet Gigabit da LOViZ contratada pelo
                              assinante está sujeita a verificação de
                              viabilidade técnica no ato da instalação.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Franquia de Dados</span>
                            <span>A franquia de dados é ilimitada.</span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Garanta uma melhor experiência de navegação
                            </span>
                            <span>
                              Os dispositivos (smartphones, computadores,
                              tablets, etc.) precisam ter as configurações
                              mínimas necessárias para que atinjam o máximo de
                              desempenho, tanto via cabo como via Wi-Fi. O modem
                              é dual-band, ou seja, tem frequência de 2,4 GHz e
                              5 GHz no Wi-Fi. Verifique se os seus dispositivos
                              permitem a navegação em ambas as frequências. A
                              conexão via cabo proporciona maior estabilidade e
                              não sofre tantas interferências quanto a rede
                              Wi-Fi.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Phone Infinity ilimitado
                            </span>
                            <span>
                              Ligações para celular e fixo de qualquer operadora
                              do Brasil. Para realizar chamadas para outro DDD,
                              basta discar 0 + DDD + Número de telefone. De
                              acordo com a Lei 20.089/2019, por determinação do
                              estado do Paraná, o serviço Identificador de
                              Chamadas é gratuito no fixo contratado.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Suporte ao Cliente 24h
                            </span>
                            <span>
                              Conte com nossos especialistas 24h para te ajudar
                              com problemas de tecnologia, tirar dúvidas e
                              realizar diagnósticos sempre que precisar. Ligue e
                              fale com nossos especialistas: 41 3797-7777.
                            </span>

                            <hr color="#000000" />
                            <span className="fs-20">Termos Promocionais</span>
                            <span>
                              Consulte nossos{" "}
                              <a
                                href="/termos-promocionais"
                                className="text-primary"
                              >
                                Termos Promocionais
                              </a>
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Reajuste anual</span>
                            <span>
                              O valor mensal da oferta poderá, a critério da
                              LOViZ, ser reajustada anualmente de acordo com a
                              variação positiva do IGP-DI ou índice geral que o
                              substitua.
                            </span>
                          </div>
                        </td>
                      </tr>
                    </CustomModal>
                  ) : null}
                  {showModal1 ? (
                    <CustomModal
                      scrollable={true}
                      title="Detalhes da oferta"
                      setShowModal={setShowModal1}
                    >
                      <tr>
                        <td>
                          <div className="d-flex flex-column">
                            <span className="fs-20">
                              LOViZ Gigabit: 600 MEGA
                            </span>
                            <span>600 MEGA + Acesso a LOViZ BOOK </span>
                            <span>
                              Valor Mensal do Pacote: 139,99 / mês no Boleto ou
                              Débito em conta
                            </span>
                            <span>
                              Oferta Especial para pagamento até a Data do
                              Vencimento: 119,99 / mês no Boleto ou Débito em
                              conta
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Instalação Profissional
                            </span>
                            <span>
                              A oferta escolhida possui instalação profissional
                              de um técnico especializado da LOViZ.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Prazo de Instalação</span>
                            <span>
                              A instalação ocorre em até 7 dias úteis a partir
                              da data de agendamento. De Segunda a Sábado, de
                              08:00 às 20:00.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Equipamento</span>
                            <span>
                              O modem Super Wi-Fi da LOViZ será entregue em
                              comodato mediante solicitação. O cliente pode
                              optar por utilizar o próprio dispositivo.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Velocidade do Plano</span>
                            <span>
                              Essas são as velocidades máximas nominais. A
                              velocidade pode variar devido a fatores externos.
                              Promocionalmente, a franquia é ilimitada.
                              Velocidade contratada é a taxa de transmissão ou
                              velocidade da conexão. É o quão rápido um pacote
                              de dados do seu dispositivo vai ser levado até a
                              operadora e vice-versa. A velocidade do serviço de
                              Internet Gigabit da LOViZ contratada pelo
                              assinante está sujeita a verificação de
                              viabilidade técnica no ato da instalação.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Franquia de Dados</span>
                            <span>A franquia de dados é ilimitada.</span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Garanta uma melhor experiência de navegação
                            </span>
                            <span>
                              Os dispositivos (smartphones, computadores,
                              tablets, etc.) precisam ter as configurações
                              mínimas necessárias para que atinjam o máximo de
                              desempenho, tanto via cabo como via Wi-Fi. O modem
                              é dual-band, ou seja, tem frequência de 2,4 GHz e
                              5 GHz no Wi-Fi. Verifique se os seus dispositivos
                              permitem a navegação em ambas as frequências. A
                              conexão via cabo proporciona maior estabilidade e
                              não sofre tantas interferências quanto a rede
                              Wi-Fi.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Phone Infinity ilimitado
                            </span>
                            <span>
                              Ligações para celular e fixo de qualquer operadora
                              do Brasil. Para realizar chamadas para outro DDD,
                              basta discar 0 + DDD + Número de telefone. De
                              acordo com a Lei 20.089/2019, por determinação do
                              estado do Paraná, o serviço Identificador de
                              Chamadas é gratuito no fixo contratado.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Suporte ao Cliente 24h
                            </span>
                            <span>
                              Conte com nossos especialistas 24h para te ajudar
                              com problemas de tecnologia, tirar dúvidas e
                              realizar diagnósticos sempre que precisar. Ligue e
                              fale com nossos especialistas: 41 3797-7777.
                            </span>

                            <hr color="#000000" />
                            <span className="fs-20">Termos Promocionais</span>
                            <span>
                              Consulte nossos{" "}
                              <a
                                href="/termos-promocionais"
                                className="text-primary"
                              >
                                Termos Promocionais
                              </a>
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Reajuste anual</span>
                            <span>
                              O valor mensal da oferta poderá, a critério da
                              LOViZ, ser reajustada anualmente de acordo com a
                              variação positiva do IGP-DI ou índice geral que o
                              substitua.
                            </span>
                          </div>
                        </td>
                      </tr>
                    </CustomModal>
                  ) : null}
                  {showModal2 ? (
                    <CustomModal
                      scrollable={true}
                      title="Detalhes da oferta"
                      setShowModal={setShowModal2}
                    >
                      <tr>
                        <td>
                          <div className="d-flex flex-column">
                            <span className="fs-20">
                              LOViZ Gigabit: 1000 MEGA
                            </span>
                            <span>1000 MEGA + Acesso a LOViZ BOOK </span>
                            <span>
                              Valor Mensal do Pacote: 219,99 / mês no Boleto ou
                              Débito em conta
                            </span>
                            <span>
                              Oferta Especial para pagamento até a Data do
                              Vencimento: 199,99 / mês no Boleto ou Débito em
                              conta
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Instalação Profissional
                            </span>
                            <span>
                              A oferta escolhida possui instalação profissional
                              de um técnico especializado da LOViZ.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Prazo de Instalação</span>
                            <span>
                              A instalação ocorre em até 7 dias úteis a partir
                              da data de agendamento. De Segunda a Sábado, de
                              08:00 às 20:00.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Equipamento</span>
                            <span>
                              O modem Super Wi-Fi da LOViZ será entregue em
                              comodato mediante solicitação. O cliente pode
                              optar por utilizar o próprio dispositivo.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Velocidade do Plano</span>
                            <span>
                              Essas são as velocidades máximas nominais. A
                              velocidade pode variar devido a fatores externos.
                              Promocionalmente, a franquia é ilimitada.
                              Velocidade contratada é a taxa de transmissão ou
                              velocidade da conexão. É o quão rápido um pacote
                              de dados do seu dispositivo vai ser levado até a
                              operadora e vice-versa. A velocidade do serviço de
                              Internet Gigabit da LOViZ contratada pelo
                              assinante está sujeita a verificação de
                              viabilidade técnica no ato da instalação.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Franquia de Dados</span>
                            <span>A franquia de dados é ilimitada.</span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Garanta uma melhor experiência de navegação
                            </span>
                            <span>
                              Os dispositivos (smartphones, computadores,
                              tablets, etc.) precisam ter as configurações
                              mínimas necessárias para que atinjam o máximo de
                              desempenho, tanto via cabo como via Wi-Fi. O modem
                              é dual-band, ou seja, tem frequência de 2,4 GHz e
                              5 GHz no Wi-Fi. Verifique se os seus dispositivos
                              permitem a navegação em ambas as frequências. A
                              conexão via cabo proporciona maior estabilidade e
                              não sofre tantas interferências quanto a rede
                              Wi-Fi.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Phone Infinity ilimitado
                            </span>
                            <span>
                              Ligações para celular e fixo de qualquer operadora
                              do Brasil. Para realizar chamadas para outro DDD,
                              basta discar 0 + DDD + Número de telefone. De
                              acordo com a Lei 20.089/2019, por determinação do
                              estado do Paraná, o serviço Identificador de
                              Chamadas é gratuito no fixo contratado.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Suporte ao Cliente 24h
                            </span>
                            <span>
                              Conte com nossos especialistas 24h para te ajudar
                              com problemas de tecnologia, tirar dúvidas e
                              realizar diagnósticos sempre que precisar. Ligue e
                              fale com nossos especialistas: 41 3797-7777.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Termos Promocionais</span>
                            <span>
                              Consulte nossos{" "}
                              <a
                                href="/termos-promocionais"
                                className="text-primary"
                              >
                                Termos Promocionais
                              </a>
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Reajuste anual</span>
                            <span>
                              O valor mensal da oferta poderá, a critério da
                              LOViZ, ser reajustada anualmente de acordo com a
                              variação positiva do IGP-DI ou índice geral que o
                              substitua.
                            </span>
                          </div>
                        </td>
                      </tr>
                    </CustomModal>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
