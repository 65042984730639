import React from "react";
import ContactUs from "../../Components/ContactUs";

import Banner from "../../Parts/Residence/Banner";
import Included from "../../Parts/Residence/Included";
import Prepare from "../../Parts/Residence/Prepare";
import NewPlans from "../../Parts/Residence/NewPlans";

import "./style.css";
function Residence() {
  return (
    <div id="company-page" className="mt-5">
      <Banner />
      <Prepare />
      <NewPlans planTitle="Residenciais" />
      <Included />
      <ContactUs
        title="Fale com um representante de vendas"
        subtitle={
          <p>
            Preencha o formulário abaixo e um de nossos representantes entrará
            em contato. Se você deseja falar com alguém imediatamente, acesse o
            <a href="https://api.whatsapp.com/send?phone=4137977777">
              {" "}
              WhatsApp 41 3797-7777.{" "}
            </a>
          </p>
        }
        link={
          "https://api.whatsapp.com/send?phone=4137977777&text=Planos%20Residenciais"
        }
      />
    </div>
  );
}

export default Residence;
