import React from "react";
import "./style.css";

export default function Phone() {
  const plans = [
    {
      sub_title: "Número VoIP",
      color: "blue-plan",
      text: "Número Virtual ou Número VoIP, é uma linha telefônica virtual onde você poderá receber ligações de qualquer número, seja ele celular ou fixo de qualquer operadora.",
      block_title: "NÚMERO VoIP",
      block_value: "9,99",
      topics: [
        "Compatível com o WhatsApp Business",
        "Compatível com APP, PABX e Telefone IP",
        "Fixo Local Ilimitado",
        "Portabilidade",
      ],
    },
    {
      sub_title: "E1 SIP",
      color: "red-plan",
      text: "E-Carrier  + SIP-Session Initiation Protocol. Para empresas com grande volume de ligações e compatível com diversos modelos de PABX IP.",
      block_title: "E1 SIP",
      block_value: "199,99",
      topics: [
        "1 Número Tronco",
        "30 Canais SIP",
        "Compatível com o WhatsApp Business",
        "Compatível com APP, PABX e Telefone IP",
        "Fixo Local Ilimitado",
        "Portabilidade",
      ],
    },
  ];
  return (
    <section id="company-phone" className="bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title text-center">
              Telefonia <span className="c-primary">Corporativa</span>
            </h2>
          </div>
        </div>
        {plans.map((plan, index) => (
          <div className="row p-2 d-flex align-items-center mobile-gap-2">
            <div className="col-md-6 mobile-text-center" key={index}>
              <h3 className="c-primary">{plan.sub_title}</h3>
              <p className="fs-18 p-2">{plan.text}</p>
            </div>
            <div className="col-md-6 ">
              <div className="card rounded-card">
                <div className="card-title d-flex justify-content-between align-items-center">
                  <div
                    className={`plan-header-speed text-white fw-bold fs-4 ${plan.color} d-flex align-items-center`}
                  >
                    {plan.block_title}
                  </div>
                  <div className="plan-header-value fs-4 fw-bold d-flex p-3">
                    <h2 className="fw-bold">{plan.block_value}/ mês</h2>
                  </div>
                </div>
                <div className="card-body">
                  <ul>
                    {plan.topics.map((topic, index) => (
                      <li key={index} className="plan-list">
                        {topic}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="card-footer bg-white">
                  <div className="d-grid">
                    <a
                      href="https://api.whatsapp.com/send?phone=4137977777"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-primary text-white p-3"
                    >
                      Assine
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
