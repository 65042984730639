import React from "react";
import "./style.css";

function Terms() {
  return (
    <section>
      <div className="container">
        <div className="row mt-20 mb-20 justify-content-center">
          <div className="col-md-8 pb-40 mobile-pb-16 col-md-8 text-justify d-flex flex-column gap-3">
            <h1 className="text-center mobile-text-center mobile-fs-25 text-decoration-underline">
              Termos Promocionais na LOViZ
            </h1>
            <span className="fs-14 fw-600">
              Todos os planos ofertados caracterizam mera expectativa ao
              cliente, eis que estarão sujeitos à aferição prévia de viabilidade
              técnica pela Prestadora.
            </span>
            <span className="fs-14 fw-600">
              Todos os interessados que contratarem os planos de acesso à
              internet ofertados pela prestadora farão jus ao desconto nas
              mensalidades do serviço. A promoção vigorará pelo período de
              01/01/2020 à 31/12/2020 e é destinada para pessoa física e
              jurídicas, os assinantes que aderirem à oferta estarão sujeitos a
              um prazo mínimo de fidelidade de 12 meses. O cancelamento antes de
              transcorrido o prazo de 12 meses ensejará a cobrança de multa,
              conforme estipulado no contrato de permanência.
            </span>
            <span className="fs-14 fw-600">
              O valor promocional dos planos será aplicado para pagamento
              realizado até a data de vencimento; a velocidade ofertada
              caracteriza o máximo possível a ser obtido a qual independente
              ação ou vontade, pode não ser atingida devido a fatores externos e
              características intrínsecas à internet; a performance do Wi-fi
              pode variar de acordo com o ambiente em que for instalado e
              dispositivos do cliente. Valores válidos para as cidades de
              Coimbra, Guiricema, São Geraldo, São Sebastião da Vargem Alegre,
              Tocantins, Ubá, Viçosa e Visconde do Rio Branco.
            </span>
            <span className="fs-14 fw-600">
              A <span className="fw-bold">LOViZ</span> reserva-se no direito de
              alterar e retirar, a qualquer momento, quaisquer condições, eis
              que delas não se origina direito adquirido, sem prejuízo do já
              concedido até a sua cessação. Os serviços poderão a qualquer tempo
              serem afetados ou temporariamente interrompidos por motivos
              técnicos/operacionais, casos fortuitos ou de força maior, em razão
              de reparos ou manutenções necessárias à prestação dos serviços, a
              qualquer tempo, independentemente de aviso prévio não cabendo a
              prestadora qualquer ônus ou penalidade advindas de tais
              eventualidades.
            </span>
            <span className="fs-14 fw-600">
              Vide regulamento de promoção no endereço eletrônico ou na central
              de relacionamento da Prestadora.
            </span>
            <a href="/mandirituba" className="style-href">
              Acesse os Termos Promocionais - Mandirituba
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Terms;
