import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import Wifi from "./Pages/Wifi";
import Gigabit from "./Pages/Gigabit";
import Phone from "./Pages/Phone";
import Iot from "./Pages/Iot";
import Condominium from "./Pages/Condominium";
import Contact from "./Pages/Contact";
import Via from "./Pages/Via";
import Education from "./Pages/Education";
import Company from "./Pages/Company";
import TrabalheConosco from "./Pages/TrabalheConosco";
import Ecossistema from "./Pages/Ecossistema";
import Socio from "./Pages/Socio";
import Solutions from "./Pages/Solutions";
import Privacidade from "./Pages/Privacidade";
import Residence from "./Pages/Residence";
import Promocionais from "./Pages/Promocionais";
import Lojas from "./Pages/Lojas";
import Whatsapp from "./Components/Whatsapp";
import Business from "./Pages/Business";
import Compliance from "./Pages/Compliance";
import LPResidencias from "./Pages/LPResidencia";
import LPEmpresas from "./Pages/LPEmpresas";
import PageNotFound from "./Pages/NotFound";
import LPHeader from "./Components/LPHeader";
import Mandirituba from "./Pages/Mandirituba";

const ScrollToTop = ({ setShowMobileMenu }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowMobileMenu(false);
  }, [pathname]);

  return null;
};

export default function Routes() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  window.dataLayer.push({
    event: "pageview",
  });
  return (
    <>
      <Router>
        <ScrollToTop setShowMobileMenu={setShowMobileMenu} />
        {window.location.pathname !== "/para-residencias-lp" &&
        window.location.pathname !== "/para-empresas-lp" ? (
          <Header
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={setShowMobileMenu}
          />
        ) : (
          <LPHeader
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={setShowMobileMenu}
          />
        )}

        <CookieConsent
          location="bottom"
          style={{
            background: "#606060",
            color: "#ddd",
            justifyContent: "center",
            flex: "0 0 100%",
            textAlign: "center",
          }}
          buttonStyle={{
            background: "#a5a0a0",
            padding: "6px 9px",
            borderRadius: "3px",
            textAlign: "center",
            marginTop: "0",
          }}
          contenetStyle={{ flex: "0 0 100%", textAlign: "center" }}
          buttonText="Aceitar"
          cookieName="myAwesomeCookieName2"
          expires={150}
        >
          Utilizamos Cookies e Ferramentas de Coleta Automática de Dados. Acesse{" "}
          <a className="cookieButton" href="/termo-uso-politica-privacidade">
            {" "}
            Termo de Uso e Política de Privacidade
          </a>
        </CookieConsent>
        {window.location.pathname !== "/para-residencias-lp" &&
        window.location.pathname !== "/para-empresas-lp" ? (
          <Whatsapp />
        ) : (
          ""
        )}

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/super-wifi">
            <Wifi />
          </Route>
          <Route path="/gigabit-internet">
            <Gigabit />
          </Route>
          <Route path="/telefone">
            <Phone />
          </Route>
          <Route path="/iot">
            <Iot />
          </Route>
          <Route path="/educacao">
            <Education />
          </Route>
          <Route path="/para-condominios">
            <Condominium />
          </Route>
          <Route path="/para-empresas">
            <Company />
          </Route>
          <Route path="/trabalhe-conosco">
            <TrabalheConosco />
          </Route>
          <Route path="/fale-conosco">
            <Contact />
          </Route>
          <Route path="/ecossistema">
            <Ecossistema />
          </Route>
          <Route path="/socio">
            <Socio />
          </Route>
          <Route path="/solucoes">
            <Solutions />
          </Route>
          <Route path="/politica-privacidade">
            <Privacidade />
          </Route>
          <Route path="/termos-promocionais">
            <Promocionais />
          </Route>
          <Route path="/2-via">
            <Via />
          </Route>
          <Route path="/para-residencias">
            <Residence />
          </Route>
          <Route path="/lojas">
            <Lojas />
          </Route>
          <Route path="/a-loviz">
            <Business />
          </Route>
          <Route path="/compliance">
            <Compliance />
          </Route>
          <Route path="/para-residencias-lp">
            <LPResidencias />
          </Route>
          <Route path="/para-empresas-lp">
            <LPEmpresas />
          </Route>
          <Route path="/mandirituba">
            <Mandirituba />
          </Route>
          <Route component={PageNotFound} />
        </Switch>
        {window.location.pathname !== "/para-residencias-lp" &&
        window.location.pathname !== "/para-empresas-lp" ? (
          <Footer />
        ) : (
          ""
        )}
      </Router>
    </>
  );
}
