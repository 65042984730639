import React from "react";

function Terms() {
    return (
        <section>
            <div className="container">
                <div className="row mt-20 mb-20 justify-content-center">
                    <div className="col-md-8 pb-40 mobile-pb-16 col-md-8 text-justify d-flex flex-column gap-3">
                                <span className="fs-20 fw-600 text-center text-decoration-underline">
                                   Política de Privacidade da LOViZ
                                </span>
                        <span className="fs-14 fw-600">
                                    A presente <span className="fw-bold"> Política de Privacidade</span> tem por finalidade demonstrar o
                                    compromisso da <span
                            className="fw-bold">FIBER ONE TELECOMUNICAÇÕES DO BRASIL LTDA</span>, doravante
                                    simplesmente <span className="fw-bold">LOViZ</span>, empresa regularmente constituída, inscrita no CNPJ
                                    sob o n°. 12.308.023/0001-47, com sede na Rua Emanuel Kant, n° 60, Sala 702,
                                    Bairro Capão Raso, na cidade de Curitiba, Estado do Paraná, com a privacidade
                                    e a proteção dos dados pessoais coletados de seus <span
                            className="fw-bold">USUÁRIO</span>, estabelecendo as
                                    regras sobre a coleta, registro, armazenamento, uso, compartilhamento, enriquecimento
                                    e eliminação dos dados coletados dentro do escopo dos serviços prestados pela <span
                            className="fw-bold">LOViZ</span>,
                                    acordo com as leis em vigor.
                                    </span>
                        <span className="fs-14 fw-600">
                                    Como condição para acesso e uso dos serviços a serem prestados pela
                                    <span className="fw-bold"> LOViZ</span>,o <span className="fw-bold">USUÁRIO</span> declara que fez a leitura completa e atenta das regras deste documento, estando
                                    plenamente ciente, conferindo assim sua livre e expressa concordância com os termos aqui
                                    estipulados. Caso não esteja de acordo com estas Diretivas, deverá descontinuar o seu acesso.
                                </span>
                        <span className="fs-20 fw-600 text-center text-decoration-underline">
                                    1. DADOS COLETADOS, FORMA E FINALIDADE DA COLETA:
                                </span>
                        <span className="fs-14 fw-600">
                                    1.1. Quando o <span className="fw-bold">USUÁRIO</span> realiza o cadastro e/ou preenche
                                    formulários fornecidos pela <span className="fw-bold">LOViZ</span>,
                                    determinados dados pessoais solicitados serão mantidos em sigilo e serão utilizados apenas
                                    para o propósito que motivou o cadastro, conforme tabela a seguir:
                                </span>
                        <table className="table table-bordered table-responsive">
                            <thead>
                            <tr>
                                <th scope="col"><span className="fs-14 fw-700">TIPOS DE DADOS</span></th>
                                <th scope="col"><span className="fs-14 fw-700">DADOS PESSOAIS</span></th>
                                <th scope="col"><span className="fs-14 fw-700">FINALIDADE DE USO DOS DADOS</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="fs-14  fw-600">Dados cadastrais</td>
                                <td className="fs-14  fw-600">- Nome completo<br/>

                                    - E-mail<br/>

                                    - Data de nascimento<br/>

                                    - Número do telefone<br/>

                                    - Endereço completo
                                </td>
                                <td className="fs-14  fw-600">- Identificar o <span
                                    className="fw-bold">USUÁRIO</span>;<br/>

                                    - Portabilidade dos dados cadastrais para outro Provedor caso solicitado
                                    pelo <span className="fw-bold">USUÁRIO</span>;<br/>

                                    - Cumprir as obrigações decorrentes das Resoluções da Anatel;<br/>

                                    - Informar sobre novidades, funcionalidades, ofertas e demais eventos
                                    relevantes para a manutenção do relacionamento com o <span
                                        className="fw-bold">USUÁRIO</span>;<br/>

                                    - Promover nossos serviços;<br/>

                                    - Responder às solicitações e pedidos de informações do <span
                                        className="fw-bold">USUÁRIO</span>;<br/>

                                    - Cumprimento de obrigações legais e regulatórias.
                                </td>
                            </tr>
                            <tr>
                                <td className="fs-14  fw-600">Dados de identificação digital</td>
                                <td className="fs-14  fw-600">- Endereço IP;<br/>

                                    - Registros de conexão;<br/>

                                    - Session ID – Cookies;<br/>

                                    - Registros de acesso;
                                </td>
                                <td className="fs-14  fw-600">- Identificar o <span
                                    className="fw-bold">USUÁRIO</span>;<br/>

                                    - Cumprimento de obrigações legais e regulatórias;<br/>

                                    - Atendimento a solicitações de autoridades administrativas ou
                                    judiciais;
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <span className="fs-14 fw-600">
                                    1.2. A <span className="fw-bold">LOViZ</span> não será responsável pela precisão, veracidade ou falta
                                    dela nas informações que o <span className="fw-bold">USUÁRIO</span> prestar à empresa ou por eventual desatualização,
                                    eis que é de responsabilidade exclusiva do <span className="fw-bold">USUÁRIO</span> prestá-las com exatidão e atualizá-las.
                                </span>
                        <span className="fs-14 fw-600">
                                    1.2.1. Todas as tecnologias utilizadas respeitarão sempre a legislação vigente e os termos
                                    desta <span className="fw-bold">Política de Privacidade</span>.
                                </span>
                        <span className="fs-14 fw-600">
                                    1.3. O consentimento que o <span className="fw-bold">USUÁRIO </span>
                                    fornece para as finalidades de uso dos dados é coletado
                                    de forma individual, clara, específica e legítima.
                                </span>
                        <span className="fs-14 fw-600">
                                    1.4. Por meio do canal de atendimento, o <span className="fw-bold">USUÁRIO </span>
                                    poderá alterar suas concessões de consentimento para tratamento de seus dados, conceder
                                    novas permissões ou retirar seu consentimento para as permissões atuais,
                                    sendo avisado das consequências que a retirada de consentimento poderá causar.
                                </span>
                        <span className="fs-14 fw-600">
                                    1.5. Os dados coletados e as atividades registradas também poderão ser compartilhados:
                                </span>
                        <span className="fs-14 fw-600">
                                    a) Com autoridades judiciais, administrativas ou governamentais competentes,
                                    sempre que houver determinação legal, requerimento, requisição ou ordem judicial;
                                </span>
                        <span className="fs-14 fw-600">
                                    b) De forma automática em caso de movimentações societárias, como fusão, aquisição e incorporação.
                                </span>
                        <span className="fs-14 fw-600">
                                    1.6. A base de dados formada por meio da coleta de dados é de nossa propriedade e
                                    responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necessários,
                                    serão feitos dentro dos limites e propósitos dos nossos negócios e descritos nesta <span
                            className="fw-bold">Política
                                    de Privacidade</span> e na legislação vigente.
                                </span>
                        <span className="fs-14 fw-600">
                                    1.6.1. Informações de identificação pessoal não serão vendidas ou alugadas pela <span
                            className="fw-bold">LOViZ</span>.
                                </span>
                        <span className="fs-14 fw-600">
                                    1.6.2. O USUÁRIO é corresponsável pelo sigilo de seus dados pessoais.
                                    O compartilhamento de senhas e dados de acesso viola esta <span
                            className="fw-bold">Política de Privacidade</span>.<br/>
                                    Não serão enviados e-mails solicitando que o <span
                            className="fw-bold">USUÁRIO</span> encaminhe seus dados cadastrais.
                                </span>
                        <span className="fs-14 fw-600">
                                    1.7. Internamente, os dados de que coletamos são acessados somente
                                    por profissionais devidamente autorizados, respeitando os princípios
                                    de proporcionalidade, necessidade e relevância para os objetivos da <span
                            className="fw-bold">LOViZ</span>,
                                    além do compromisso de confidencialidade e preservação da privacidade nos termos
                                    desta <span className="fw-bold">Política de Privacidade</span>.
                                </span>
                        <span className="fs-20 fw-600 text-center text-decoration-underline">
                                    2. COMO ARMAZENAMOS OS DADOS E REGISTROS
                                </span>
                        <span className="fs-14 fw-600">
                                    2.1. Os dados coletados e os registros de atividades serão armazenados
                                    em ambiente seguro e controlado pelo prazo mínimo estipulado conforme a tabela abaixo:
                                </span>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th scope="col"><span className="fs-12 fw-700">DADOS PESSOAIS</span></th>
                                <th scope="col"><span className="fs-12 fw-700">PRAZO DE ARMAZENAMENTO</span>
                                </th>
                                <th scope="col"><span className="fs-12 fw-700">FUNDAMENTO LEGAL</span></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className="fs-14 fw-600">Dados cadastrais</td>
                                <td className="fs-14 fw-600">1 ano</td>
                                <td className="fs-14 fw-600">Art. 53 Resolução 614 da Anatel</td>
                            </tr>
                            <tr>
                                <td className="fs-14 fw-600">Dados de identificação digital</td>
                                <td className="fs-14 fw-600">6 meses ou 1 ano</td>
                                <td className="fs-14 fw-600">Art. 13 e 15, Marco Civil da Internet</td>
                            </tr>
                            </tbody>
                        </table>

                        <span className="fs-14 fw-600">
                                    2.2. Caso haja solicitação do <span className="fw-bold">USUÁRIO</span>, os dados poderão ser apagados
                                    antes deste prazo. No entanto, pode ocorrer de os dados precisarem ser
                                    mantidos por período superior, por motivo de lei, ordem judicial (art.
                                    11, II, “a” da Lei Geral de Proteção de Dados “LGPD”, Lei nº 13.709/2018),
                                    proteção ao crédito (art. 7º, X, LGPD) e outros interesses legítimos, em
                                    conformidade com o artigo 10 da LGPD. Findo o prazo e a necessidade legal,
                                    serão excluídos com uso de métodos de descarte seguro, ou utilizados de
                                    forma anonimizada para fins estatísticos.
                                </span>
                        <span className="fs-14 fw-600">
                                    2.4. Poderá ser necessário que a <span className="fw-bold">LOViZ</span> transmita seus dados pessoais a
                                    outras empresas afiliadas, no qual a mesma faz parte, ou a um prestador
                                    de serviços externo. Tais prestadores de serviço podem, por exemplo, ser
                                    contratados para a oferta de produtos e distribuição de material promocional.
                                    A <span className="fw-bold">LOViZ</span> exige que todas as empresas afiliadas e seus prestadores de serviço
                                    externos utilizem os dados pessoais do <span className="fw-bold">USUÁRIO</span>
                                    em conformidade com esta <span className="fw-bold">Política de Privacidade</span>.
                                </span>
                        <span className="fs-20 fw-600 text-center text-decoration-underline">
                                    3. EXIBIÇÃO, RETIFICAÇÃO, LIMITAÇÃO, OPOSIÇÃO E EXCLUSÃO DE DADOS:
                                </span>
                        <span className="fs-14 fw-600">
                                    3.1. O <span className="fw-bold">USUÁRIO</span> pode solicitar a exibição ou retificação de seus
                                    dados pessoais, por meio dos seguintes canais:
                                </span>
                        <span className="fs-14 fw-600">
                                    - SAC: acessível através do seguinte endereço de e-mail <a
                            href="mailto:contato@loviz.com.vc" className="text-danger">
                                    contato@loviz.com.vc</a>, endereço eletrônico <a href="/index" title=""
                                                                                     className="text-danger">www.loviz.com.vc</a>,
                                    ou pelo telefone <a href="tel:4137977777" className="text-danger">41 3797-7777</a>
                                </span>
                        <span className="fs-14 fw-600">
                                    3.2 Pela mesma ferramenta, o <span className="fw-bold">USUÁRIO</span> poderá também:
                                </span>
                        <span className="fs-14 fw-600">
                                    (i) requerer a limitação do uso de seus dados pessoais;
                                </span>
                        <span className="fs-14 fw-600">
                                    (ii) manifestar sua oposição ao uso de seus dados pessoais;
                                </span>
                        <span className="fs-14 fw-600">
                                    (iii) solicitar a portabilidade dos dados cadastrais, em formato legível, para outra Prestadora do mesmo ramo de nossa atuação;
                                </span>
                        <span className="fs-14 fw-600">
                                    (iv) solicitar a exclusão de seus dados pessoais que coletamos, desde que eventuais Contas de Acesso tenham sido canceladas e decorrido o prazo legal mínimo relacionado à guarda de dados.
                                </span>
                        <span className="fs-14 fw-600">
                                    3.2.1. Caso o <span className="fw-bold">USUÁRIO</span> retire seu consentimento para finalidades fundamentais
                                    ao regular funcionamento da Central do Assinante, os serviços e funcionalidades
                                    poderão ficar indisponíveis.
                                </span>
                        <span className="fs-14 fw-600">
                                    3.2.2. Caso o <span className="fw-bold">USUÁRIO</span> não conceda seu consentimento para as
                                    finalidades facultativas, relacionadas ao envio de informações
                                    novidades, conteúdos, ofertas, notícias e demais eventos relevantes
                                    para a manutenção do relacionamento, os serviços e funcionalidades
                                    continuarão sendo disponibilizados regularmente no endereço eletrônico da Prestadora.
                                </span>
                        <span className="fs-14 fw-600">
                                    3.3. Para fins de auditoria, segurança, controle de fraudes e preservação
                                    de direitos, poderemos permanecer com o histórico de registro dos dados do
                                    <span className="fw-bold">USUÁRIO</span> por prazo maior nas hipóteses que a lei ou norma regulatória assim
                                    estabelecer ou para preservação de direitos.
                                </span>
                        <span className="fs-20 fw-600 text-center text-decoration-underline">
                                   4. COOKIES
                                </span>
                        <span className="fs-14 fw-600">
                                    4.1. Cookies são pequenos arquivos de texto que são armazenados em seu
                                    equipamento terminal (computador ou dispositivo móvel) quando você visita alguns sites.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.2. Os Cookies permitem que uma Plataforma Digital memorize informações sobre
                                    a visita do <span className="fw-bold">USUÁRIO</span>, o seu idioma preferido, a sua localização, a recorrência das
                                    suas sessões e outras variáveis que a <span className="fw-bold">LOViZ</span> considera relevantes para tornar a
                                    experiência muito mais eficiente. Esses Cookies também serão usados para ajudar a
                                    determinar a utilidade, interesse e o número de utilizações dos sites, permitindo
                                    uma navegação mais rápida e eficiente e eliminando a necessidade de introduzir repetidamente as mesmas informações.
                                    Eles não armazenam dados pessoais nem coletam informações de identificação pessoal.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.3. Cookies de publicidade comportamental on-line
                                </span>
                        <span className="fs-14 fw-600">
                                     4.3.1. A <span className="fw-bold">LOViZ</span> reserva-se o direito de usar informações obtidas por meio de
                                    Cookies a partir de uma análise do comportamento de utilização dos <span
                            className="fw-bold">USUÁRIOs</span>, a
                                    fim de exibir publicidade específica para alguns dos nossos serviços. A <span
                            className="fw-bold">LOViZ</span>
                                    acredita que esta ação o beneficie o <span className="fw-bold">USUÁRIO</span>, porque são exibidos conteúdos ou
                                    publicidade que a mesma acredita corresponder aos interesses do <span
                            className="fw-bold">USUÁRIO</span> com base em seu comportamento de navegação,
                                    pois verá menos publicidade exibida aleatoriamente e menos conteúdos que não são de seu interesse.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.4. Como prevenir que os cookies sejam armazenados no seu disco rígido e/ou eliminá-los
                                </span>
                        <span className="fs-14 fw-600">
                                    4.4.1. A maioria dos navegadores de Internet são configurados para aceitar automaticamente
                                    os Cookies. O <span className="fw-bold">USUÁRIO</span> poderá alterar as configurações para bloquear o uso de Cookies ou
                                    alertá-lo quando um Cookie estiver sendo enviado para seu dispositivo.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.4.2. Depois de autorizar o uso de Cookies, o <span
                            className="fw-bold">USUÁRIO</span> pode sempre desativar
                                    parte ou a totalidade dos nossos Cookies.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.4.3. Todos os browsers permitem ao utilizador aceitar, recusar ou apagar Cookies,
                                    nomeadamente através da seleção das definições apropriadas no respectivos navegadores.
                                    O <span className="fw-bold">USUÁRIO</span> pode configurar os Cookies no menu "opções" ou "preferências" do seu browser.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.4.4. Note-se que, ao desativar cookies, pode impedir que alguns
                                    serviços da web funcionem corretamente, afetando, parcial ou totalmente, a navegação no website.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.5. Cookies utilizados e respectivas finalidades.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.5.1. Google Analytics:
                                </span>
                        <span className="fs-14 fw-600">
                                    4.5.1.1. Serviço de análise web fornecido pela Google, Inc. (“Google”).
                                    O Google Analytics utiliza uma forma específica de “Cookies”, ou seja, arquivos
                                    de texto, que são armazenados no seu computador e permitem a análise do seu uso do site.
                                    A informação gerada pelo Cookie acerca da sua utilização do site será transmitida e
                                    armazenada em um servidor da Google nos EUA.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.5.1.2. A Google usa esta informação em nome da <span
                            className="fw-bold">LOViZ</span> para analisar a utilização do <span
                            className="fw-bold">USUÁRIO</span>,
                                    a fim de compilar relatórios sobre atividades do site e fornecer serviços adicionais relacionados
                                    ao seu uso e uso da Internet para o operador do site. O endereço IP transmitido para o Google
                                    Analytics pelo navegador do <span className="fw-bold">USUÁRIO</span> não está consolidado com outros dados da Google. O <span
                            className="fw-bold">USUÁRIO</span>
                                    poderá impedir o armazenamento de Cookies através da definição adequada do software de navegação.
                                    Além disso, poderá impedir que a Google grave e processe os dados gerados pelos Cookies e relacionados
                                    ao uso do site (incluindo endereço IP), baixando e instalando o plug-in disponível no link e informações
                                    adicionais sobre os termos de uso e proteção de dados em https://www.google.com/analytics/terms/br.html
                                    ou https://www.google.com.br/intl/pt-BR/policies/privacy/.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.5.2. Google Adwords:
                                </span>
                        <span className="fs-14 fw-600">
                                    4.5.2.1. Um serviço de análise da Google, e rastreamento de conversões,
                                    que faz parte do Google AdWords. Funciona da seguinte maneira: quando
                                    o <span className="fw-bold">USUÁRIO</span> clica em um anúncio exibido pela Google, o Google AdWords armazena
                                    um Cookie no rastreamento de conversões (um “Cookie de conversão”) no disco
                                    rígido do computador do <span className="fw-bold">USUÁRIO</span>. Estes cookies perdem a validade após 30 dias
                                    e não permitem que o <span className="fw-bold">USUÁRIO</span> seja pessoalmente identificado. Ao visitar
                                    determinadas páginas, a <span className="fw-bold">LOViZ</span> e o Google conseguem reconhecer que o <span
                            className="fw-bold">USUÁRIO </span>
                                    clicou no anúncio e foi redirecionado para essa página.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.5.2.2. As informações obtidas através dos Cookies de conversão têm como objetivo
                                    criar estatísticas para os clientes AdWords que utilizam o rastreamento de conversões.
                                    Através destas estatísticas, é possível descobrir o número de <span
                            className="fw-bold">USUÁRIO</span> que clicaram no
                                    anúncio exibido pela Google e que acessaram a página assinalada pela etiqueta de
                                    rastreamento de conversões.
                                </span>
                        <span className="fs-14 fw-600">
                                    4.5.2.3. Também é utilizado o Google Analytics para analisar dados do
                                    AdWords e os Cookies do “DoubleClick” para efeitos estatísticos. Caso não
                                    pretenda que isso seja feito, o <span className="fw-bold">USUÁRIO</span> poderá desativá-lo através do Gestor de preferências de anúncios.
                                </span>
                        <span className="fs-14 fw-600">
                                    5. DISPOSIÇÕES GERAIS
                                </span>
                        <span className="fs-14 fw-600">
                                    5.1. Não utilizamos nenhum tipo de decisão automatizada que impacte o <span
                            className="fw-bold">USUÁRIO</span>.
                                </span>
                        <span className="fs-14 fw-600">
                                    5.2. Temos o direito de alterar o teor desta <span className="fw-bold">Política de Privacidade</span> a qualquer
                                    momento, conforme a finalidade ou necessidade, tal qual para adequação e conformidade
                                    legal de disposição de lei ou norma que tenha força jurídica equivalente, cabendo
                                    ao <span className="fw-bold">USUÁRIO</span> verificá-la sempre que efetuar o acesso no endereço eletrônico da <span
                            className="fw-bold">LOViZ</span>.
                                </span>
                        <span className="fs-14 fw-600">
                                    5.2.1. Ocorrendo atualizações neste documento e que demandem nova
                                    coleta de consentimento, serão notificadas por meio dos contatos que
                                    nos fornece no cadastro.
                                </span>
                        <span className="fs-14 fw-600">
                                    5.3 Em caso de qualquer dúvida com relação às disposições
                                    constantes desta <span className="fw-bold"> Política de Privacidade</span>, o <span
                            className="fw-bold">USUÁRIO</span>  poderá entrar
                                    em contato por meio dos canais de atendimento apontados a seguir:
                                    telefone de atendimento no <a href="tel:4137977777" className="text-danger">41 3797-7777</a>,
                                    endereço eletrônico <a href="/index" title=""
                                                           className="text-danger">www.loviz.com.vc</a>, e-mail <a
                            href="mailto:contato@loviz.com.vc" className="text-danger">
                                    contato@loviz.com.vc</a>
                                </span>
                        <span className="fs-14 fw-600">
                                    5.4. Caso empresas terceirizadas realizem o processamento de quaisquer dados
                                    que coletamos, as mesmas deverão respeitar as condições aqui estipuladas e nossas
                                    normas de Segurança da Informação, obrigatoriamente.
                                </span>
                        <span className="fs-14 fw-600">
                                    5.5. Caso alguma disposição desta <span
                            className="fw-bold">Política de Privacidade</span> seja considerada ilegal
                                    ou ilegítima por autoridade da localidade em que resida ou da sua conexão à Internet,
                                    as demais condições permanecerão em pleno vigor e efeito.
                                </span>
                        <span className="fs-14 fw-600">
                                    5.6. O <span className="fw-bold">USUÁRIO</span> reconhece que toda comunicação realizada por e-mail (aos endereços informados
                                    no seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra forma digital,
                                    virtual e digital também são válidas, eficazes e suficiente para a divulgação de qualquer
                                    assunto que se refere aos serviços que prestamos, bem como às condições de sua prestação
                                    ou a qualquer outro assunto nele abordado, ressalvadas as disposições expressamente diversas
                                    previstas nesta <span className="fw-bold">Política de Privacidade</span>.
                                </span>
                        <span className="fs-20 fw-600 text-center text-decoration-underline">
                                    6. LEI APLICÁVEL E JURISDIÇÃO
                                </span>
                        <span className="fs-14 fw-600">
                                    6.1. A presente <span className="fw-bold">Política de Privacidade</span> será regida e interpretada segundo a
                                    legislação brasileira, no idioma português, sendo eleito o Foro da Comarca de domicílio
                                    do <span className="fw-bold">USUÁRIO</span> para dirimir qualquer litígio ou controvérsia envolvendo o presente documento,
                                    salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.
                                </span>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Terms;
