import React, { useState } from "react";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Faq() {
  const [activeQuestionLeft, setActiveQuestionLeft] = useState(-1);
  const [activeQuestionRight, setActiveQuestionRight] = useState(-1);
  const handleClick = (index) => {
    setActiveQuestionLeft(index === activeQuestionLeft ? -1 : index);
  };

  const handleClickRight = (index) => {
    setActiveQuestionRight(index === activeQuestionRight ? -1 : index);
  };

  const questionsLeft = [
    {
      question: "Qual é o canal de atendimento da LOViZ?",
      answer: (
        <div className="d-flex flex-column">
          <span>
            A LOViZ está presente em diversos canais, possibilitando assim uma
            melhor comunicação com seus clientes. Nosso atendimento é 24 horas
            por dia, 7 dias na semana, incluindo sábados, domingos e feriados
          </span>
          <span>
            Whatsapp:{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://api.whatsapp.com/send?phone=4137977777&text=Falar%20com%20WhatsApp%20"
            >
              41 3797-7777
            </a>
          </span>
          <span>
            Ligação Gratuita:{" "}
            <a href="tel:554137977777" title="Ligar diretamente para LOViZ">
              41 3797-7777
            </a>
          </span>
          <span>
            Atendimento exclusivo empresarial ou Ligação direta:{" "}
            <a href="tel:554137977777" title="Ligar diretamente para LOViZ">
              41 3797-7777
            </a>
          </span>
          <span>
            Facebook:{" "}
            <a href="https://www.facebook.com/loviz.com.vc" target="_blank">
              facebook.com/loviz.com.vc
            </a>
          </span>
          <span>
            Portal do cliente{" "}
            <a href="https://portal.loviz.com.vc/">portal.loviz.com.vc</a>
          </span>
          <span>
            Instagram:{" "}
            <a href="http://instagram.com/lovizcomvc" target="_blank">
              instagram.com/lovizcomvc
            </a>
          </span>
        </div>
      ),
    },
    {
      question: "Quais as vantagens da Internet fibra da LOViZ?",
      answer:
        "A fibra da LOViZ chega dentro da casa do cliente, permitindo que o ciclo de transmissão óptica fique completa, desde a rede central até o modem do assinante. Assim, os benefícios da Fibra são garantidos: alta capacidade de transmissão e qualidade do sinal são mantidas até a sua casa. Além disso com a fibra LOViZ você terá mais performance, menos interferência, mais estabilidade e melhor experiência para acessar sites, ver vídeos, jogar online e muito mais, além do modem Wi-Fi Extreme que proporciona a melhor experiência ao navegar pelo Wi-Fi.",
    },
    {
      question:
        "Qualquer pessoa ou empresa pode contratar a internet da LOViZ?",
      answer: (
        <div>
          Sim. A LOViZ oferece planos de internet ultra banda larga para pessoas
          físicas e jurídicas. Consulte a disponibilidade através do telefone ou
          WhatsApp{" "}
          <a
            href='https://api.whatsapp.com/send?phone=4137977777" +
                                "&text=Falar%20com%20WhatsApp%20'
            target="_blank"
          >
            41 3797-7777
          </a>
        </div>
      ),
    },
    {
      question:
        "Qual a vantagem da internet LOViZ em comparação aos serviços disponíveis no mercado?",
      answer: (
        <div>
          A LOViZ oferece acesso em banda extra larga por meio de cabos de fibra
          óptica (uma das melhores tecnologias de tráfego de dados, voz e
          vídeo). Oferecemos produtos e serviços altamente especializados a um
          preço justo e competitivo.
        </div>
      ),
    },
    {
      question: "O contrato da LOViZ possui fidelidade?",
      answer: (
        <div>
          Nosso serviço pode ser adquirido com ou sem fidelidade.Em caso da
          escolha da contratação com fidelidade de 12 meses, se ocorrer
          cancelamento do serviço antes desse período, implicará no pagamento de
          valor compensatório, conforme contrato. O valor a ser cobrado é
          proporcional ao tempo que falta para acabar a fidelidade.
        </div>
      ),
    },
    {
      question: "A LOViZ tem atendimento 24 horas?",
      answer: (
        <div>
          Sim. A equipe LOViZ atende pelo WhatsApp/Telefone{" "}
          <a
            href='https://api.whatsapp.com/send?phone=4137977777"
                                "&text=Falar%20com%20WhatsApp%20'
            target="_blank"
          >
            41 3797-7777
          </a>
          , 7 dias da semana, 24 horas por dia, incluindo sábados, domingos e
          feriados.
        </div>
      ),
    },
  ];

  const questionsRight = [
    {
      question: "Qual é o prazo de instalação?",
      answer:
        "Durante o atendimento junto ao comercial, serão dadas sugestões de data de instalação para o cliente.",
    },
    {
      question: "A velocidade da internet LOVıZ é garantida?",
      answer: (
        <div>
          Por lei, todas as operadoras devem seguir os artigos{" "}
          <a href="https://informacoes.anatel.gov.br/legislacao/resolucoes/2011/57-resolucao-574#art16">
            "16 a 18 da Resolução nº 574/2011 da Anatel"
          </a>{" "}
          Porém a LOViZ trabalha para entregar uma conexão e experiência muito
          superior ao que a lei exige.
        </div>
      ),
    },
    {
      question: "O que é Wi-Fi Extreme?",
      answer:
        "O Wi-Fi Extreme é entregue pelo nosso modem de última geração (tecnologia Wi-Fi AC), que proporciona uma melhor conexão e experiência. Graças a essa tecnologia, você poderá navegar em ultra velocidades, através do Wi-Fi, utilizando seus aparelhos que também possuam a tecnologia Wi-Fi AC.",
    },
    {
      question:
        "Preciso contratar telefone fixo para ter a internet Ultra Banda Larga da LOViZ?",
      answer:
        "Não, a ativação do serviço de telefonia fixa é opcional, não obrigatória.",
    },
    {
      question:
        "Existe algum limite mensal de Download e Upload com a ultra banda larga da LOViZ?",
      answer:
        "Não, com a ultra banda larga da LOViZ, você poderá navegar sem limites e não pagará nada a mais por isso.",
    },
  ];

  return (
    <section id="faq">
      <div className="container">
        <h1 className="c-gray fs-24 font-itallic text-center">
          Perguntas Frequentes
        </h1>
        <h2 className="fs-18 c-gray font-italic fw-600 mobile-fs-20 text-center">
          Ficou na dúvida? Quem sabe uma das perguntas abaixo pode te ajudar.
        </h2>
        <div className="row mt-5">
          <div className="col-12 col-md-6">
            <div className="accordion">
              {questionsLeft.map((question, index) => (
                <div key={index} className="card">
                  <a
                    className="btn btn-link"
                    type="button"
                    onClick={() => handleClick(index)}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="fs-18 c-gray font-itallic text-start">
                        {question.question}
                      </span>
                      {index === activeQuestionLeft ? (
                        <FontAwesomeIcon
                          icon={faMinus}
                          size="sm"
                          className="c-gray"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faPlus}
                          size="sm"
                          className="c-gray"
                        />
                      )}
                    </div>
                  </a>
                  <div
                    className={`collapse ${
                      index === activeQuestionLeft ? "show" : ""
                    }`}
                    id={`question-${index}`}
                    data-parent="#accordion"
                  >
                    <div className="card-body fs-18">{question.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="accordion">
              {questionsRight.map((question, index) => (
                <div key={index} className="card">
                  <a
                    className="btn btn-link"
                    type="button"
                    onClick={() => handleClickRight(index)}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="fs-18 c-gray font-itallic text-start">
                        {question.question}
                      </span>
                      {index === activeQuestionRight ? (
                        <FontAwesomeIcon
                          icon={faMinus}
                          size="sm"
                          className="c-gray"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faPlus}
                          size="sm"
                          className="c-gray"
                        />
                      )}
                    </div>
                  </a>
                  <div
                    className={`collapse ${
                      index === activeQuestionRight ? "show" : ""
                    }`}
                    id={`question-${index}`}
                    data-parent="#accordion"
                  >
                    <div className="card-body fs-18">{question.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
