import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faTwitter,
  faTiktok,
  faLinkedin,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

function NewsletterFooter() {
  return (
    <>
      {/*<div className="row mt-30">*/}
      {/*  <div className="col-md-12">*/}
      {/*    <form*/}
      {/*      action=""*/}
      {/*      id="footer-newsletter-form"*/}
      {/*      className="position-relative"*/}
      {/*    >*/}
      {/*      <input type="email" required placeholder="nome@gmail.com" />*/}
      {/*      <button type="submit">*/}
      {/*        <FontAwesomeIcon icon={faPaperPlane} />*/}
      {/*      </button>*/}
      {/*    </form>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="row mt-30">
        <div className="col-md-12 d-flex align-items-center">
          <a
            href="https://api.whatsapp.com/send?phone=4137977777&text=Eu%20quero%20acessar%20a%20central%20do%20assinante"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              // size="15px"
              className="c-primary mr-10"
              icon={faWhatsapp}
            />
          </a>
          <a
            href="https://www.facebook.com/loviz.com.vc"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              // size="15px"
              className="c-primary"
              icon={faFacebook}
            />
          </a>
          <a
            href="https://www.instagram.com/lovizcomvc"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              // size="15px"
              className="c-primary ml-10"
              icon={faInstagram}
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UC9DkGndCgrXvXco8dmYsXAg"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              // size="15px"
              className="c-primary ml-10"
              icon={faYoutube}
            />
          </a>
          <a
            href="https://twitter.com/LOViZvc"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              // size="15px"
              className="c-primary ml-10"
              icon={faTwitter}
            />
          </a>
          <a
            href="https://www.tiktok.com/@lovizcomvc"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              // size="15px"
              className="c-primary ml-10"
              icon={faTiktok}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/loviz"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon
              // size="15px"
              className="c-primary ml-10"
              icon={faLinkedin}
            />
          </a>
        </div>
      </div>
      <div className="row mt-30">
        <div className="col-md-12">
          <a
            href="https://api.whatsapp.com/send?phone=4137977777"
            target="_blank"
            rel="noreferrer"
            className="text-center"
          >
            <FontAwesomeIcon
              // size="15px"
              className="c-primary"
              icon={faWhatsapp}
            />
            <span className="ml-10 text-center">
              Fale conosco pelo WhatsApp:
            </span>
            <br />
            <span className="ml-10 text-center">41 3797-7777</span>
          </a>
        </div>
      </div>
    </>
  );
}

export default NewsletterFooter;
