import React from "react";
import { useEffect } from "react";

import "./style.css";

function Banner({
  background,
  principalTitle,
  secondaryTitle,
  description,
  classPrincipalTitleColor = "c-dark",
  classFontColor = "c-primary",
  backgroundOverlay = "rgb(255, 255, 255) 30%, transparent",
  backgroundPosition,
}) {
  useEffect(() => {
    const anime = document.querySelectorAll("[data-anime]");
    anime.forEach((el, index) => {
      setTimeout(() => el.classList.add("active"), index * 10);
    });
  }, []);

  return (
    <section
      id="intro"
      className="d-flex align-items-center"
      style={{
        background: `linear-gradient(to right, ${backgroundOverlay}), url(${background}) ${backgroundPosition} no-repeat`,
      }}
    >
      <div className="container">
        <div className="row" data-anime>
          <div className="intro-title col-md-12 text-left">
            <h1
              className={classPrincipalTitleColor + " mobile-fs-25"}
              dangerouslySetInnerHTML={{ __html: principalTitle }}
            />
          </div>
        </div>
        <div className="row mt-40 mobile-mt-20" data-anime>
          <div className="col-md-12 intro-subtitle text-left">
            {secondaryTitle ? (
              <h2
                className={classFontColor}
                dangerouslySetInnerHTML={{ __html: secondaryTitle }}
              />
            ) : null}
            {description ? (
              <span
                className={`font-italic ${classFontColor}`}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
