import React from "react";

import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import TagManager from "react-gtm-module";

function Whatsapp() {
  TagManager.dataLayer({
    dataLayer: {
      event: "Botão do WhatsApp",
      pagePath: "https://loviz.com.vc/",
      pageTitle: "LOViZ - Internet Ultra Rápida",
    },
  });
  return (
    <div>
      <a
        href="https://api.whatsapp.com/send?phone=4137977777"
        className="float"
        target="_blank"
        onClick={() => window.dataLayer.push({ event: "Botão do WhatsApp" })}
      >
        <FontAwesomeIcon icon={faWhatsapp} className="c-white my-float" />
      </a>
    </div>
  );
}

export default Whatsapp;
