import React from "react";

import img1 from "../../assets/img/Solutions/img-list-1.webp";
import img2 from "../../assets/img/Solutions/img-list-2.webp";
import img3 from "../../assets/img/Solutions/img-list-3.webp";
import img4 from "../../assets/img/Solutions/img-list-4.webp";
import img5 from "../../assets/img/Solutions/img-list-5.webp";
import img6 from "../../assets/img/Solutions/img-list-6.webp";

import Intro from "../../Parts/Solutions/Intro";
import List from "../../Parts/Solutions/List";

export default function Solutions() {

  const itens = [
    {
      title: "Seja um Franqueado",
      content: `
      Também acredita em um mundo melhor? Tem interesse em levar tecnologia, velocidade e amor a milhares de Brasileiros? Torne-se um franqueado LOViZ. 
    `,
      image: img1,
      isInverted: false,
      containLink: true,
      linkText: "Saiba mais",
      linkTo: "https://api.whatsapp.com/send?phone=4137977777",
      id:"rede-neutra"
    },

    {
      title: "Rede Neutra",
      content: `
      O compartilhamento oneroso de infraestrutura é prática incentivada pela ANATEL (art. 2º da Lei das Antenas) e forma de parceria e crescimento mútuo de empresas. Deseja disponibilizar sua infraestrutura à LOViZ ? 
    `,
      image: img2,
      isInverted: true,
      containLink: true,
      linkText: "Fale conosco",
      linkTo: "https://api.whatsapp.com/send?phone=4137977777&text=Rede%20neutra",
      id: "atacado"
    },

    {
      title: "Atacado",
      content: `
      Go big or go home! A LOViZ não para de crescer e contratos e negociações com fornecedores que prezam a venda e o fornecimento por atacado saem na frente.
    `,
      image: img3,
      isInverted: false,
      containLink: true,
      linkText: "Fale com um de nossos Consultores para saber mais.",
      linkTo: "https://api.whatsapp.com/send?phone=4137977777&text=Falar%20com%20um%20consultor",
      id: "isp"
    },

    {
      title: "ISP",
      content: `
      Acreditamos no poder do compartilhamento e da parceria. Convidamos demais Provedores de Internet para conversar e juntos crescer e criar um mundo melhor! Tem interesse em ser parceiro da LOViZ?
    `,
      image: img4,
      isInverted: true,
      containLink: true,
      linkText: "Fale agora com nosso time!",
      linkTo: "https://api.whatsapp.com/send?phone=4137977777&text=Seja%20nosso%20parceiro",
      id: "swap"
    },

    {
      title: "SWAP",
      content: `
      Quer investir na LOViZ? Realizar uma troca de fluxos de caixa? Converse com um de nossos Consultores Financeiros para verificar nossa disponibilidade de ofertas.
    `,
      image: img5,
      isInverted: false,
      containLink: true,
      linkText: "Fale conosco.",
      linkTo: "https://api.whatsapp.com/send?phone=4137977777&text=Swap",
      id: "projetos-especiais"
    },

    {
      title: "Projetos Especiais",
      content: `
      Uma ideia revolucionária? Uma solicitação específica? Um projeto especialmente desenvolvido para determinado fim? Sim, amamos o que fazemos e temos orgulho em poder criar e desenvolver algo do zero. Vamos trabalhar juntos em um projeto especial? 
    `,
      image: img6,
      isInverted: true,
      containLink: true,
      linkText: "Fale conosco.",
      linkTo: "https://api.whatsapp.com/send?phone=4137977777&text=Projetos%20especiais"
    },
  ];

  return (
    <div id="solution-page" className="mt-5">
      <Intro />
      <section id="solution-list">
        <div className="container">
          {itens.map(({ title, content, containLink, image, linkText, linkTo, isInverted,id }, index) => (
            <List
              title={title}
              content={content}
              containLink={containLink}
              image={image}
              linkText={linkText}
              linkTo={linkTo}
              key={index}
              isInverted={isInverted}
              id={id}
            />
          ))}
        </div>
      </section>
    </div>
  );
}
