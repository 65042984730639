import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import ReactInputMask from "react-input-mask";
import { Alert } from "react-bootstrap";
import "./style.css";
import ReCAPTCHA from "react-google-recaptcha";

export default function FormCep({
  sectionClass = "",
  parentColumnSize = "7",
  hideText = false,
  responsiveFieldsColumn = "col-12 col-sm-12 col-md-12 col-lg-8",
  responsiveButtonColumn = "col-12 col-sm-12 col-md-12 col-lg-4",
  componentText = "Verifique se os serviços LOViZ estão disponíveis para a sua região.",
  containsWhatsAppColumn = false,
}) {
  useEffect(() => {
    const anime = document.querySelectorAll("[data-anime]");
    anime.forEach((el, index) => {
      setTimeout(() => el.classList.add("active"), index * 10);
    });
  }, []);

  const [formValue, setFormValue] = useState({
    nome: "",
    telefone: "",
  });

  const [showAlert, setShowAlert] = useState({
    show: false,
    status: "",
    header: "",
    message: "",
    button: false,
  });

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    const formPhone = formValue.telefone.replace(/[() -]/g, "");
    const today = new Date();
    const time =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const goodMorning = "12:00:00";
    const goodAfternoon = "18:00:00";
    if (time <= goodMorning) {
      var message = "Bom dia";
    } else if (time <= goodAfternoon) {
      var message = "Boa tarde";
    } else {
      var message = "Boa noite";
    }

    var data = {
      cod_conta: 1,
      hsm: 46,
      tipo_envio: 1,
      variaveis: {
        1: message,
      },
      contato: {
        nome: formValue.nome,
        telefone: "55" + formPhone,
      },
      cod_flow: 131,
      start_flow: "1",
    };

    /* A variable that is being used to send a post request to the
    lovizhub.loviz.com.vc/sendmessage/hsm/site-loviz endpoint. */
    var config = {
      method: "post",
      url: "https://lovizhub.loviz.com.vc/sendmessage/hsm/site-loviz",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    if (formPhone === "") {
      setShowAlert({
        show: true,
        status: "danger",
        header: "Ops!!",
        message:
          "Parece que você não preencheu o campo de número, tente preenchê-lo antes de checar a disponibilidade novamente.",
      });
    } else {
      axios(config)
        .then(function (response) {
          console.log(response.status);
          if (JSON.stringify(response.data.cod_error) === "10") {
            setShowAlert({
              show: true,
              status: "warning",
              header: "Ops!!",
              message:
                "Parece que você não preencheu o campo de numero corretamente, tente novamente colocando seu DDI + DDD",
            });
          } else if (JSON.stringify(response.data.cod_error) === "0") {
            setShowAlert({
              show: true,
              status: "success",
              header:
                formValue.nome === ""
                  ? "Que ótimo!"
                  : `Que ótimo, ${formValue.nome}!`,
              message:
                "Recebemos seu número e em breve vamos entrar em contato com você para conversarmos sobre disponibilidade.",
            });
          } else {
            setShowAlert({
              show: true,
              status: "danger",
              header: "Erro!!",
              message:
                "Opa! Infelizmente não foi possível checar a disponibilidade! Por favor, entre em contato conosco pelo WhatsApp!",
              button: true,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    /* A function that is being called when the form is submitted. */
  };

  const captchaRef = useRef(null);

  function checkCaptcha(value) {
    const button = document.getElementById("submitButton");
    button.classList.remove("d-none");
  }

  return (
    <section className={`${sectionClass}`} id="form-cep">
      <div className="container">
        <div className="row justify-content-center">
          <div className={`col-md-${parentColumnSize}`}>
            <div className="form-section-cep text-left" data-anime>
              {!hideText ? (
                <div className="row">
                  <div className="col-md-12">
                    <span className="font-italic c-gray fs-18">
                      {componentText}
                    </span>
                  </div>
                </div>
              ) : null}
              <form
                className="row form-cep mt-30 align-items-center"
                onSubmit={handleSubmit}
              >
                <div className={`${responsiveFieldsColumn} inputs-fields`}>
                  <div className="row justify-content-around">
                    <input
                      type="text"
                      name="nome"
                      placeholder="Seu nome"
                      className="col-sm-12 col-lg-5 text-input fonts-input"
                      value={formValue.nome}
                      onChange={handleChange}
                    />
                    <ReactInputMask
                      onChange={handleChange}
                      className="col-sm-12 col-lg-5 text-input fonts-input"
                      name="telefone"
                      placeholder="Seu WhatsApp"
                      mask="(99) 9 9999-9999"
                    />
                    {/* <input
                      type="text"
                      name="telefone"
                      placeholder="Whatsapp (+55 00 00000-0000)"
                      className="col-sm-12 col-lg-5 text-input fonts-input"
                      value={formValue.telefone}
                      onChange={handleChange}
                    /> */}
                  </div>
                </div>
                <div
                  className={`align-self-center ${responsiveButtonColumn} submit-form`}
                >
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    ref={captchaRef}
                    onChange={checkCaptcha}
                  />
                  <button
                    id="submitButton"
                    className="fonts-button blue-button form-cep-button d-none"
                  >
                    Checar Disponibilidade
                  </button>
                </div>
              </form>
              {showAlert.show ? (
                <Alert
                  className="mt-5 .alert-dismissible fade show"
                  variant={showAlert.status}
                  onClose={() => setShowAlert({ show: false })}
                >
                  <Alert.Heading>{showAlert.header}</Alert.Heading>
                  <p>{showAlert.message}</p>
                  {showAlert.button && (
                    <a
                      className="fonts-button green-button"
                      href="https://api.whatsapp.com/send?phone=4137977777"
                      target="_blank"
                    >
                      Chamar Whatsapp
                    </a>
                  )}
                </Alert>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
