import Tabela1 from "../../assets/img/Mandirituba/tabela-1.png";

export default function Mandirituba() {
  return (
    <div className="container">
      <div className="row mobile-margin-5">
        <div className="col-md-12 border-radius-12">
          <h1 className="fs-45 ptb-20 text-center mobile-fs-25 mobile-text-center">
            TERMOS PROMOCIONAIS - Mandirituba
          </h1>
        </div>
        <div className="col-md-12 border-radius-12">
          <h3 className="fs-30 ptb-20 text-center mobile-fs-25 mobile-text-center">
            REGULAMENTO DA OFERTA PROMOCIONAL - 600 MEGA + Leitura
          </h3>
        </div>
      </div>
      <div className="col-md-12">
        <span className="fw-bold">1. OBJETO </span> <br />
        <span className="text-justify ms-3">
          1.1.O presente documento é aplicável juntamente com o Contrato de
          Prestação dos Serviços de Comunicação Multimídia (SCM) e o Contrato de
          Serviço Telefônico Fixo Comutado (STFC), e tem a finalidade de
          registrar as características das Ofertas indicadas no item 3 deste
          Regulamento, em atendimento ao Art. 50 da Resolução nº 632/2014 da
          Anatel, e regular as relações entre a LOVIZ ENTRETENIMENTO E
          TELECOMUNICAÇÕES LTDA. inscrita no CNPJ sob o nº. 39.742.520/0001-05,
          com sede na Avenida Comendador Franco n° 331, Bairro Jardim Botânico,
          CEP: 80.215-090, na Cidade de Curitiba- PR, Telefone/WhatsApp de
          atendimento: 41 3797-7777, site: www.loviz.com.vc doravante
          denominada simplesmente “LOViZ” e o CLIENTE, que foi devidamente
          qualificado no TERMO DE ADESÃO, que adquirir a Oferta indicada no
          presente documento.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">2. ELEGIBILIDADE E ABRANGÊNCIA</span>
        <span className="text-justify ms-3">
          2.1. Podem usufruir dos benefícios contemplados nesta Oferta os
          clientes, tanto pessoas físicas quanto jurídicas, que sejam novos ou
          atuais, e que realizem a adesão no período compreendido entre
          10/07/2024 e 31/07/2024. Esta oferta é válida exclusivamente para o
          município de Mandirituba/PR, sujeita à disponibilidade técnica
          necessária para a prestação dos serviços.
        </span>
        <span className="text-justify ms-3">
          2.2. A continuidade do período de Oferta está sujeita ao critério
          exclusivo da LOViZ e pode ser prorrogada ou encerrada sem a
          necessidade de notificação prévia.
        </span>
        <span className="text-justify ms-3">
          2.3. As condições promocionais aqui presentes não são cumulativas com
          outras Ofertas, a menos que expressamente previsto nos Regulamentos
          correspondentes. Ao tornar-se elegível e optar por esta Oferta, o
          CLIENTE reconhece que poderá perder os benefícios da Promoção anterior
          à qual estava vinculado, caso esta não seja cumulativa com a presente.
        </span>
        <span className="text-justify ms-3">
          2.4. No caso de a LOViZ decidir pela descontinuação desta oferta, seu
          encerramento será devidamente comunicado com 30 (trinta) dias de
          antecedência.
        </span>
        <span className="text-justify ms-3">
          2.5. A participação na Oferta está sujeita à consulta e aprovação de
          crédito
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          3. OPÇÕES DE SERVIÇOS, PACOTES/FRANQUIA, ADESÃO E BENEFÍCIOS
        </span>
        <span className="text-justify ms-3">
          3.1. A LOViZ oferece os mesmos planos detalhados neste documento sem a
          necessidade de compromisso de fidelização. O CLIENTE tem total
          liberdade para decidir se deseja aderir à Oferta com ou sem a
          obrigação de permanência mínima.
        </span>
        <span className="text-justify ms-3">
          3.2. Os valores promocionais a seguir estão em vigor pelo período
          indicado, conforme descrito no item 5, e são exclusivamente destinados
          aos CLIENTES elegíveis, como definido no item 2.
        </span>
        <div class="container mt-5">
          <div class="col-md-12 mt-3 d-flex flex-column gap-2">
            <table class="table table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">NOME DA OFERTA</th>
                  <th scope="col">SERVIÇOS DIGITAIS (SVA) INCLÚSOS</th>
                  <th scope="col">
                    PREÇO MENSAL ORIGINAL SEM OFERTA PROMOCIONAL SEM FIDELIDADE
                  </th>
                  <th scope="col">
                    PREÇO MENSAL PARA ADESÕES PARTICIPANTES DA OFERTA
                    PROMOCIONAL COM FIDELIDADE¹
                  </th>
                  <th scope="col">
                    VALOR PROMOCIONAL 3 PRIMEIRAS MENSALIDADES COM FIDELIDADE
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>600 MEGA + Leitura</td>
                  <td>
                    Conta Outra Vez; LOViZ BOOK by Eulalia; Máquina de Contos;
                    Vamolê
                  </td>
                  <td>R$169,99</td>
                  <td>R$119,99</td>
                  <td>R$59,99</td>
                </tr>
              </tbody>
            </table>
            <span>Tabela 1 - Quadro de Planos de Serviços</span>

            <small>
              ¹Valores promocionais com fidelidade de 12 (doze) meses.
            </small>
          </div>

          <div class="col-md-12 mt-3 d-flex flex-column gap-2">
            <table class="table table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col">
                    TAXA DE ADESÃO VALOR NÃO PROMOCIONAL SEM FIDELIDADE
                  </th>
                  <th scope="col">
                    TAXA DE ADESÃO VALOR PROMOCIONAL COM FIDELIDADE DE 12 MESES
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>R$800,00</td>
                  <td>R$0,00</td>
                </tr>
              </tbody>
            </table>
            <span>Tabela 2 - Quadro de Valores de Adesão</span>
          </div>
        </div>

        <span className="text-justify ms-3">
          3.3. Os valores mencionados neste regulamento são valores brutos e
          estarão sujeitos a qualquer tributo aplicável sobre o objeto deste
          contrato, bem como a eventuais mudanças nas alíquotas dos tributos que
          atualmente incidem. Esses acréscimos serão aplicados automaticamente.
        </span>
        <span className="text-justify ms-3">
          3.4. Cancelamento da oferta com fidelização:
        </span>
        <span className="text-justify ms-5">1. Inadimplência;</span>
        <span className="text-justify ms-5">
          2. Descumprimento das obrigações contratuais;
        </span>
        <span className="text-justify ms-5">
          3. Adesão a outras promoções não cumulativas com a presente Oferta;1.
          Inadimplência;
        </span>
        <span className="text-justify ms-5">
          4. Alteração da fatura digital para a fatura física.
        </span>
        <span className="text-justify ms-3">
          3.5. Para fins de verificação de fraude, a LOViZ, dentro das
          determinações legais, poderá monitorar o consumo, cancelar ou
          suspender, imediatamente os benefícios desta oferta, caso seja
          constatado consumo ou utilização fraudulenta e/ou indevida dos mesmos,
          incluindo-se, mas não se limitando, ao repasse e/ou comercialização de
          qualquer dos serviços contratados.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">4. VALORES E DESCONTOS PROMOCIONAIS</span>
        <span className="text-justify ms-3">
          4.1. O valor da mensalidade sem fidelidade é de R$169,99 (cento e
          sessenta e nove reais e noventa e nove centavos). Para clientes com
          fidelidade e estando dentro deste período de fidelidade, o valor da
          mensalidade é de R$119,99 (cento e dezenove reais e noventa e nove
          centavos).
        </span>
        <span className="text-justify ms-3">
          4.2. Promocionalmente, os clientes que aderirem ao plano com
          fidelidade receberão um desconto nas três primeiras mensalidades,
          pagando R$59,99 (cinquenta e nove reais e noventa e nove centavos) por
          mês durante este período inicial. Após as três primeiras mensalidades,
          o valor será reajustado para R$119,99 (cento e dezenove reais e
          noventa e nove centavos).
        </span>
        <span className="text-justify ms-3">
          4.3. A instalação dos serviços contratados somente será realizada
          mediante o pagamento do valor correspondente a primeira mensalidade. O
          pagamento deverá ser efetuado antes da instalação. A não realização do
          pagamento resultará na suspensão da instalação até que o pagamento
          seja realizado. A segunda mensalidade será calculada pro rata die,
          considerando o período entre a data da instalação e o próximo
          fechamento de ciclo da fatura, a qual ocorrerá no próximo dia 24
          (vinte e quatro).
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          5. VIGÊNCIA DA OFERTA PROMOCIONAL COM FIDELIDADE
        </span>
        <span className="text-justify ms-3">
          5.1.O preço promocional com fidelidade será vigorará da 1º (primeira)
          até a 12ª (décima segunda) mensalidade.
        </span>
        <span className="text-justify ms-5">
          5.1.1 Promocionalmente, os clientes que aderirem ao plano com
          fidelidade, a 1ª (primeira), 2ª (segunda) e 3ª (terceira) mensalidade
          terão um valor promocional extra, de R$59,99 conforme cláusula 4.2.
        </span>
        <span className="text-justify ms-3">
          5.2. Após o cumprimento do período de 12 (doze) meses, o valor mensal
          poderá retornar ao montante original do plano de serviço sem
          fidelidade, conforme Tabela 1, sem a necessidade de aviso prévio. A
          continuidade do preço promocional nos meses subsequentes ficará a
          critério da LOViZ.
        </span>
        <span className="text-justify ms-3">
          5.3. Os termos deste Regulamento de Oferta Promocional podem ser
          modificados, sendo sempre disponibilizada a versão mais atualizada no
          site da LOViZ, acessível em www.loviz.com.vc
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          6. REGRAS DA PRESENTE OFERTA PROMOCIONAL
        </span>
        <span className="text-justify ms-3">
          6.1.O Contrato de Prestação dos Serviços de Comunicação Multimídia
          (SCM) aplicável a presente Oferta, sendo este diretamente vinculados a
          este regulamento, e podem ser acessados a qualquer momento pelo
          CLIENTE através dos nossos canais de atendimento e pelo site
          www.loviz.com.vc
        </span>
        <span className="text-justify ms-3">
          6.2.Os serviços promocionais mencionados neste documento são
          estritamente destinados ao uso privado e interno do CLIENTE, seja ele
          pessoa física ou jurídica. Fica expressamente proibida a
          redistribuição, revenda, compartilhamento ou transferência do serviço
          a terceiros ou para fora das instalações do CLIENTE. Sob nenhuma
          circunstância, os serviços poderão ser convertidos em
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          7. DOS SERVIÇOS DE VALOR ADICIONADO (SVA)
        </span>
        <span className="text-justify ms-3">
          7.1. Esta oferta inclui Serviços de Valor Adicionado (SVA). O custo
          desses serviços está incorporado ao preço total da oferta e será
          detalhado no extrato demonstrativo de pagamento e cobrança.
        </span>
        <div className="container">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">SERVIÇO SVA</th>
                <th scope="col">VALOR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Contra Outra Vez</td>
                <td>R$20,00</td>
              </tr>
              <tr>
                <td>LOViZ BOOK by Eulalia</td>
                <td>R$11,99</td>
              </tr>
              <tr>
                <td>Máquina de Contos</td>
                <td>R$15,00</td>
              </tr>
              <tr>
                <td>Vamolê</td>
                <td>R$25,00</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-12">
              <span className="d-block">Tabela 3 – Quadro de Valores SVA</span>
            </div>
          </div>
        </div>
        <span className="text-justify ms-3">
          7.2. Dado que se trata de uma oferta conjunta, o cancelamento de
          qualquer serviço acarretará no cancelamento integral da oferta, o que
          inclui tanto a Ultra Banda Larga LOViZ quanto quaisquer Serviços de
          Valor Adicionado (SVA) associados.
        </span>
        <span className="text-justify ms-3">
          7.3. Recomenda-se que o download e o uso dos aplicativos de SVA sejam
          feitos por meio do WiFi da LOViZ instalado no endereço do cliente, a
          fim de evitar o consumo de dados da franquia do celular.
        </span>
        <span className="text-justify ms-3">
          7.4. Caso o Cliente opte por realizar o download e/ou uso dos
          aplicativos via internet da operadora de celular, o consumo de dados
          para essas finalidades será descontado normalmente da franquia de
          dados do Cliente. A LOViZ não assume responsabilidade por reembolsos,
          descontos ou créditos relacionados a esse consumo.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          8. DO SERVIÇO DE ULTRA BANDA LARGA LOViZ
        </span>
        <span className="text-justify ms-3">
          8.1. A presente oferta prevê os seguintes benefícios:
          <ul>
            <li>
              i. Concessão de desconto tal como demonstrado nas Tabelas 1 e 2,
              que estão presentes neste documento.
            </li>
            <li>
              ii. Concessão em regime de comodato do equipamento óptico
              integrado ao roteador (necessária a utilização do serviço) já
              contemplando funcionalidade de Wi-Fi com conectividade 2.4GHz e
              5GHz, aqui designado como EQUIPAMENTO.
            </li>
          </ul>
          A experiência de uso dos serviços está condicionada às características
          dos equipamentos e terminais de propriedade do cliente, devendo estes
          apresentarem desempenho de processamento e comunicação compatíveis com
          o EQUIPAMENTO cedido em comodato e com o plano contratado.
          <br />
          O EQUIPAMENTO cedido em comodato tem desempenho de roteamento adequado
          para até 5 (cinco) dispositivos simultâneos. A utilização de mais
          dispositivos simultâneos poderá interferir na velocidade e qualidade
          da conexão.
          <br />
          Os planos de Ultra Banda Larga LOViZ, possuem as seguintes
          características dentro da oferta PROMOÇÃO 600 MEGA + LEITURA:
        </span>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">DOWNLOAD</th>
                    <th scope="col">UPLOAD</th>
                    <th scope="col">VALOR</th>
                    <th scope="col">VALOR</th>
                  </tr>
                </thead>
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">MÁXIMO</th>
                    <th scope="col">MÁXIMO</th>
                    <th scope="col">MENSAL SEM FIDELIDADE</th>
                    <th scope="col">MENSAL COM FIDELIDADE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>600mbps</td>
                    <td>300mbps</td>
                    <td>R$98,00</td>
                    <td>R$48,00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <span className="d-block">
                Tabela 4 – Características do Plano Ultra Banda Larga
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">9. REGRAS DO COMODATO DE EQUIPAMENTO</span>
        <span className="text-justify ms-3">
          9.1. O CLIENTE concorda em ficar responsável pela posse e integridade
          do(s) EQUIPAMENTO(S) necessário(s) à prestação do serviço aderido.
          O(s) EQUIPAMENTO(S) será(ão) fornecido(s) pela LOViZ em caráter de
          comodato e o CLIENTE está ciente e concorda em devolvê-lo(s), por
          ocasião da extinção ou cancelamento do Contrato, a qualquer título,
          nas mesmas condições de funcionamento, ressalvado o desgaste natural
          de uso, nos termos definidos na Cláusula Quinta do Contrato de
          Prestação do Serviços de Comunicação Multimídia (SCM).
        </span>
        <span className="text-justify ms-3">
          9.2. O CLIENTE é responsável por utilizar adequadamente o EQUIPAMENTO
          fornecido pela LOViZ e zelar por sua integridade, comprometendo-se a:
          (i) não realizar, nem permitir que terceiros não identificados pela
          LOViZ façam quaisquer intervenções ou inspeções no(s) equipamento(s);
          (ii) alterar qualquer característica original da instalação, sem
          prévia autorização da LOViZ, conforme o caso; (iii) efetuar qualquer
          espécie de reparo, manutenção ou abertura do EQUIPAMENTO para
          quaisquer fins (iv) comunicar a LOViZ a existência de quaisquer
          defeitos ou de anomalias; (v) manter o(s) EQUIPAMENTO(S) no local
          original de sua instalação, considerando-se tais ocorrências como
          falta grave ensejadora de imediata rescisão desta Oferta Promocional e
          demais sanções previstas no Contrato de Prestação do Serviços de
          Comunicação Multimídia (SCM) e no Contrato de Serviço Telefônico Fixo
          Comutado (STFC).
        </span>
        <span className="text-justify ms-3">
          9.3. O CLIENTE declara-se ciente de que, em toda e qualquer hipótese,
          a manutenção do EQUIPAMENTO cedido em comodato deverá ser feita
          exclusivamente por empregados da LOViZ, conforme o caso, ou por
          terceiros por esta autorizados.
        </span>
        <span className="text-justify ms-3">
          9.4. O CLIENTE não poderá emprestar, ceder e/ou sublocar, total ou
          parcialmente, o EQUIPAMENTO cedido em comodato.
        </span>
        <span className="text-justify ms-3">
          9.5. Em casos de danos causados ao EQUIPAMENTO por quaisquer motivos,
          incluindo, mas não se limitando às hipóteses de (i) falta de
          infraestrutura adequada; (ii) perda; (iii) roubo; (iv) furto; (v)
          incêndio; e (vi) utilização e/ou conservação indevidas, o cliente
          sujeitar-se-á ao pagamento da multa no valor vigente referente ao
          equipamento, conforme disposto na Tabela 5 do presente Regulamento.
        </span>
        <span className="text-justify ms-3">
          9.6. Qualquer que seja a hipótese de rescisão do presente contrato, o
          cliente deverá solicitar à Central de Atendimento a desconexão do
          EQUIPAMENTO objeto de comodato, disponibilizando-o para imediata
          devolução e retirada, pela LOViZ, no endereço de instalação do
          assinante, no mesmo estado em que o recebeu quando da ativação dos
          serviços, ressalvando-se tão somente o desgaste natural decorrente do
          uso normal e adequado.
        </span>
        <span className="text-justify ms-3">
          9.7. O CLIENTE declara-se ciente de que deverá estar disponível para
          receber os técnicos da LOViZ na data e período agendados para a visita
          de retirada do EQUIPAMENTO e que, em não sendo possível sua presença,
          deverá tomar as providências necessárias para que terceiros por ele
          autorizados permitam e presenciem a visitação, sob pena de arcar com
          os custos decorrentes da visita técnica improdutiva, sendo que tais
          custos estão descritos na TABELA DE PREÇOS que pode ser consultada no
          site: www.loviz.com.vc.
        </span>
        <span className="text-justify ms-3">
          9.8. A desconexão do EQUIPAMENTO será realizada apenas e
          exclusivamente por técnicos habilitados pela LOViZ, conforme o caso,
          que verificarão o seu estado de conservação e funcionamento no ato da
          retirada do EQUIPAMENTO.
        </span>
        <span className="text-justify ms-3">
          9.9. Caso a LOViZ não consiga retirar o(s) EQUIPAMENTO(S) no endereço
          de instalação por culpa do CLIENTE, o mesmo ficará responsável pela
          entrega do(s) EQUIPAMENTO(S) à LOViZ.
        </span>
        <span className="text-justify ms-3">
          9.9.1. O CLIENTE será notificado, via Central de Atendimento, carta,
          e-mail, SMS, WhatsApp ou qualquer outro meio, acerca de procedimento
          alternativo de devolução, que deverá ocorrer no prazo de 30 (trinta)
          dias, correndo às suas expensas eventuais custos decorrentes.
        </span>
        <span className="text-justify ms-3">
          9.9.1.1. O(s) equipamento(s) disponibilizados pela LOViZ deverão ser
          entregues em bom estado, em perfeito funcionamento e íntegros.
        </span>
        <span className="text-justify ms-3">
          9.9.1.2. O CLIENTE sujeitar-se-á ao pagamento da multa no valor
          vigente referente ao equipamento conforme disposto na Tabela 5 caso:
          (i) sejam constatadas avarias e/ou adulterações no EQUIPAMENTO quando
          da devolução; (ii) retenha o EQUIPAMENTO impossibilitando a sua
          retirada pela LOViZ ou ainda não efetue a devolução conforme item
          5.3.5.8.1. após o decurso do prazo de 30 (trinta) contato do
          recebimento da notificação prevista no item 5.3.5.8.1.1.
        </span>
        <span className="text-justify ms-3"></span>
        <div className="container">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">EQUIPAMENTO</th>
                <th scope="col">VALOR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ONT Dual Band AC</td>
                <td> R$450,00 (quatrocentos e cinquenta reais)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span className="text-justify ms-3">
          Tabela 5 - Tabela de Valores ONT
        </span>
        <span className="text-justify ms-3">
          9.9.1.3. Não havendo o pagamento da multa descrita na Tabela 5 nos
          prazos convencionados, a LOViZ poderá utilizar de todos os meios
          legais disponíveis para a exigência e a cobrança do respectivo valor,
          incluindo, mas não se limitando, a possibilidade de inclusão dos dados
          do cliente em órgãos de proteção ao crédito, como SCPC, SPC, SERASA e
          outros.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          10. CARACTERÍSTICAS DE ENDEREÇAMENTO E RESTRIÇÕES
        </span>
        <span className="text-justify ms-3">
          10.1. Os planos de serviços descritos neste documento, são ofertados
          mediante a distribuição de endereço IPv6 e endereço IPv4 privado com
          uso por meio de tecnologia CGNAT (Carrier Grade Address Translation) e
          variável (IP não fixo), sem possibilidade de conexões entrantes ou
          hospedagem de serviços nas dependências do cliente ou uso de
          aplicações que exijam o mapeamento de portas TCP/UDP entre endereços
          IPv4 públicos e privados.
        </span>
        <span className="text-justify ms-3">
          10.2. O serviço ofertado não suporta aplicações que necessitem de
          mapeamento de portas TCP/UDP entre o endereço IPv4 público
          compartilhado via CGNAT e o endereço IPv4 privado fornecido ao
          cliente. Entre as aplicações não suportadas são alguns modelos de DVR
          (Digital Video Recorder) de sistemas de vigilância e VPNs (Virtual
          Private Networks) IPSec. Para estes casos, propõe-se a adoção de
          aplicações compatíveis com o protocolo IPv6 ou contratação de um
          serviço específico para este fim.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">11. REGRAS DE COBRANÇA</span>
        <span className="text-justify ms-3">
          11.1. Os serviços incluídos na Oferta, serão faturados e demonstrados
          em documento de cobrança digital, estando disponíveis através do
          portal do cliente www.minhaloviz.com.vc
        </span>
        <span className="text-justify ms-3">
          11.2. Os pagamentos poderão ser efetuados através da rede bancária
          autorizada pela LOViZ, bastando para isso emitir o boleto digital no
          portal do cliente.
        </span>
        <span className="text-justify ms-3">
          11.3. As faturas serão enviadas digitalmente, todos os meses, via
          e-mail e SMS e/ou WhatsApp do CLIENTE. O não recebimento da fatura
          pelo CLIENTE não o isenta do devido pagamento.
        </span>
        <span className="text-justify ms-3">
          11.3.1 O recebimento digital, citado no item 6.3, depende de prévia
          autorização por parte do CLIENTE, a ser realizado no TERMO DE ADESÃO,
          juntamente com a correta informação e/ou atualização de seus dados
          cadastrais.
        </span>
        <span className="text-justify ms-3">
          11.3.2 É de responsabilidade do CLIENTE manter seus dados sempre
          atualizados junto a LOViZ.
        </span>
        <span className="text-justify ms-3">
          11.4. As ofertas aqui apresentadas, são exclusivamente com envio de
          fatura digital. Caso o CLIENTE deseje receber sua fatura
          impressa/física, propõe-se a contratação de oferta que inclua esta
          opção ou estará sujeito ao cancelamento dos benefícios oferecidos na
          presente Oferta, conforme mencionado no item 9.1.7
        </span>
        <span className="text-justify ms-3">
          11.5. Os planos de serviço e, consequentemente, os preços mensais como
          o desconto, serão reajustados nas condições estabelecidas no Contrato
          de Prestação dos serviços de SCM e STFC, aplicando-se sobre os valores
          reajustados o mesmo percentual de desconto que utilizando na indicação
          do benefício.
        </span>
      </div>
      <div className=" mt-3">
        <div className="col-md-12 mt-3 d-flex flex-column gap-2">
          <span className="fw-bold">
            12. OUTRAS INFORMAÇÕES E RESTRIÇÕES À UTILIZAÇÃO DAS OFERTAS DE
            SERVIÇO
          </span>
          <span className="text-justify ms-3">
            12.1. A adesão do CLIENTE à Oferta ocorrerá mediante solicitação
            junto à Central de Atendimento e/ou agentes autorizados de venda
            pela LOViZ.
          </span>
          <span className="text-justify ms-3">
            12.2. Para usufruir de uma das Ofertas, o CLIENTE deverá cumprir e
            concordar com todas as obrigações constantes do presente documento,
            bem como com as disposições dos contratos de prestação de serviços e
            demais instrumentos associados. O CLIENTE deverá estar adimplente
            com a LOViZ. Em caso de inadimplência, a Oferta poderá ser
            definitivamente excluída, voltando o CLIENTE a usufruir do Plano na
            forma original, conforme valores apresentados na Tabela 1, ou seja,
            perdendo o valor mensal da Oferta Promocional.
          </span>
          <span className="text-justify ms-3">
            12.3. O CLIENTE deverá permanecer no Plano elegível durante o
            período de fruição da Oferta, caso o CLIENTE solicite qualquer
            alteração ou migração de Plano de Serviço, especialmente no caso de
            downgrade (redução de velocidade ou valor), perderá os benefícios da
            presente Oferta, ainda que para um Plano elegível.
          </span>
          <span className="text-justify ms-3">
            12.4. Caso o CLIENTE solicite o cancelamento, troca de plano ou
            troca de titularidade, estará automaticamente desligado da Promoção,
            deixando de usufruir os benefícios decorrentes desta.
          </span>
          <span className="text-justify ms-3">
            12.5. Os CLIENTES elegíveis estão sujeitos a análise, limite e
            regras de crédito da LOViZ.
          </span>
          <span className="text-justify ms-3">
            12.6. Esta Oferta é destinada a pessoa física ou jurídica, com CPF
            ou CNPJ, devendo o CLIENTE ser o destinatário final do serviço
            prestado pela LOViZ.
          </span>
          <span className="text-justify ms-3">
            12.7. A velocidade do serviço de Ultra Banda Larga contratada está
            sujeita à verificação técnica no ato da instalação. Havendo
            impossibilidade técnica para instalação da velocidade contratada, o
            CLIENTE poderá requerer a redução da velocidade ou cancelar seu
            pedido, sem ônus.
          </span>
          <span className="text-justify ms-3">
            12.8. Em caso de ser identificado mau uso da Oferta Promocional pelo
            CLIENTE, a LOViZ poderá, a seu exclusivo critério, reduzir a
            concessão dos benefícios ou mesmo excluí-los definitivamente,
            voltando o CLIENTE ao Plano original sem eventuais promoções
            aplicáveis.
          </span>
          <span className="text-justify ms-3">
            12.8.1. O CLIENTE que for retirado da Oferta Promocional por mau uso
            e/ou fraude, ou por qualquer outro motivo que infrinja este
            regulamento, ficará impedido de se cadastrar em qualquer outra
            Oferta Promocional pelo período de 180 (cento e oitenta) dias, a
            exclusivo critério da LOViZ.
          </span>
          <span className="text-justify ms-3">
            12.9. Considera-se mau uso pelo CLIENTE, a prática de qualquer
            fraude ou desvio na utilização dos serviços promocionais, incluindo
            o uso de equipamentos eletrônicos não autorizados e o
            compartilhamento e/ou venda do seu sinal.
          </span>
          <span className="text-justify ms-3">
            12.10. As velocidades de navegação informados na Tabela 4 seguem as
            resolução e diretrizes da ANATEL.
          </span>
          <span className="text-justify ms-3">
            12.11. As velocidades indicadas na Tabela 4 podem variar de acordo
            com a taxa de utilização da rede e está sujeita a variação de
            fatores externos, alheios a vontade da LOViZ.
          </span>
          <span className="text-justify ms-3">
            12.12. É obrigação do CLIENTE garantir que a instalação dos
            equipamentos necessários à prestação dos serviços não será impedida
            por obstáculos físicos que comprometam a conclusão dos trabalhos.
          </span>
          <span className="text-justify ms-3">
            12.13. Caso não seja possível a instalação do plano de serviço
            contratado junto a LOViZ no endereço solicitado pelo CLIENTE, por
            qualquer motivo, o CLIENTE perderá o direito de participar da Oferta
            Promocional, isentando a LOViZ de qualquer responsabilidade.
          </span>
          <span className="text-justify ms-3">
            12.14. O CLIENTE fica ciente que a contratação dos planos e serviços
            apresentados neste documento é para uso pessoal e que é vedada a
            comercialização dos serviços contratados, declarando estar ciente
            ainda de que o desenvolvimento de atividades de telecomunicações sem
            a necessária autorização expedida pela Anatel constitui crime e
            multa de R$10.000,00 (dez mil reais) prevista no art. 131 e 183, da
            Lei 9.472/97.
          </span>
          <span className="text-justify ms-3">
            12.15. A LOViZ não será responsabilizada por qualquer ocorrência ou
            impossibilidade de cumprimento da presente Oferta que seja
            decorrente de fatores externos, alheios a sua vontade, em especial
            caso fortuito ou de força maior.
          </span>
          <span className="text-justify ms-3">
            12.16. Serão permitidas até 3 (três) ativações na presente Oferta
            para a mesma pessoa física (CPF) ou jurídica (CNPJ).
          </span>
        </div>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          13. PRAZO DE PERMANÊNCIA, PERÍODO E VALOR DA MULTA POR RESCISÃO
          ANTECIPADA
        </span>
        <span className="text-justify ms-3">
          13.1. Tendo em vista o desconto oferecido nos planos e taxa de adesão,
          se, em até 12 (doze) meses da adesão à Oferta, houver, por parte do
          CLIENTE, solicitação de cancelamento, migração para velocidade
          inferior, ou troca de titularidade, o CLIENTE deverá pagar multa no
          valor de R$800,00 (oitocentos reais). O valor da multa a ser cobrada é
          proporcional (pro-rata) ao período que faltar para completar 12 (doze)
          meses contados da adesão, perdendo ainda, imediatamente, o direito aos
          benefícios desta Oferta.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          14. ENCERRAMENTO DA OFERTA E CESSÃO DE BENEFÍCIOS
        </span>
        <span className="text-justify ms-3">
          14.1. O CLIENTE deixará de usufruir dos benefícios das Ofertas, em
          caso de:
        </span>
        <ul className="text-justify ms-5">
          <li>Término da Oferta;</li>
          <li>Cancelamento do serviço contratado;</li>
          <li>Extinção do plano ou serviço contratado;</li>
          <li>Inadimplência;</li>
          <li>Transferência de titularidade do serviço contratado;</li>
          <li>Descumprimento das obrigações contratuais;</li>
          <li>Mudança da fatura digital para fatura impressa/física;</li>
          <li>
            Adesão a outras promoções não cumulativas com a presente Oferta;
          </li>
          <li>
            Mudança de endereço para qualquer cidade não prevista no anexo 1
            e/ou sem cobertura da rede da LOViZ;
          </li>
          <li>
            Cancelar ou suspender temporariamente qualquer dos planos
            necessários ao funcionamento desta promoção ou de qualquer de seus
            dependentes.
          </li>
        </ul>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          15. DA PROPRIEDADE INTELECTUAL E PROTEÇÃO DE DADOS
        </span>
        <span className="text-justify ms-3">
          15.1. O CLIENTE declara e reconhece que a titularidade e os direitos
          relativos à toda e qualquer propriedade intelectual da LOViZ pertencem
          exclusivamente a essa. O acesso, pelo CLIENTE à estrutura, sistemas e
          plataformas, bem como à demais criações/projetos da LOViZ, não lhe
          conferem qualquer direito ou prerrogativa sobre propriedade
          intelectual. Ainda, na hipótese de violação, pelo CLIENTE, dos
          direitos relativos à propriedade intelectual da LOViZ, o CLIENTE
          ficará sujeito ao pagamento de indenização até a altura dos danos
          efetivamente causados à LOViZ, sem prejuízo das penalidades previstas
          na legislação em vigor.
        </span>
        <span className="text-justify ms-3">
          15.2. Em função das atividades estabelecidas neste Instrumento, a
          LOViZ declara estar ciente de suas obrigações e de acordo com a Lei n.
          13.709/2018 (Lei Geral de Proteção de Dados), uma vez que poderá ter
          acesso a dados pessoais de seus CLIENTES que são pessoas físicas e/ou
          de outras pessoas físicas relacionadas. Deste modo, a LOViZ se tornará
          responsável pelo tratamento adequado dessas informações, assim como
          pela adoção de medidas técnicas e de segurança aptas a proteger os
          dados pessoais.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">16. FORO</span>
        <span className="text-justify ms-3">
          16.1. O presente Regulamento encontra-se disponível na versão
          atualizada no site www.loviz.com.vc e obriga herdeiros e/ou
          sucessores, sendo eleito o foro do domicílio do CLIENTE participante
          da promoção como o único competente para dirimir qualquer questão
          oriunda deste Regulamento, com exclusão a qualquer outro, por mais
          privilegiado que seja ou venha a ser.
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">17. DA ASSINATURA ELETRÔNICA</span>
        <span className="text-justify ms-3">
          17.1. As Partes afirmam e declaram que o presente acordo poderá ser
          assinado por meio eletrônico, nos termos do Art. 10o §2º da MP
          2200-2/2001.
        </span>
      </div>
      <div className="d-flex flex-column align-items-center mt-3">
        {" "}
        <span className="align-self-center ms-3">
          Curitiba-PR, 09 de julho de 2024.
        </span>
      </div>
      <hr />
      <div className="row mobile-margin-5">
        <div className="col-md-12 border-radius-12">
          <h3 className="fs-30 ptb-20 text-center mobile-fs-25 mobile-text-center">
            REGULAMENTO DA OFERTA PROMOCIONAL - 600 MEGA + Leitura + Streamings
          </h3>
        </div>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">1. OBJETO</span>
        <span className="text-justify ms-3">
          1.1. O presente documento é aplicável juntamente com o Contrato de
          Prestação dos Serviços de Comunicação Multimídia (SCM) e o Contrato de
          Serviço Telefônico Fixo Comutado (STFC), e tem a finalidade de
          registrar as características das Ofertas indicadas no item 3 deste
          Regulamento, em atendimento ao Art. 50 da Resolução nº 632/2014 da
          Anatel, e regular as relações entre a LOVIZ ENTRETENIMENTO E
          TELECOMUNICAÇÕES LTDA. inscrita no CNPJ sob o nº. 39.742.520/0001-05,
          com sede na Avenida Comendador Franco n° 331, Bairro Jardim Botânico,
          CEP: 80.215-090, na Cidade de Curitiba- PR, Telefone/WhatsApp de
          atendimento: 41 3797-7777, site: www.loviz.com.vc doravante
          denominada simplesmente “LOViZ” e o CLIENTE, que foi devidamente
          qualificado no TERMO DE ADESÃO, que adquirir a Oferta indicada no
          presente documento.
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">2. ELEGIBILIDADE E ABRANGÊNCIA</span>
        <span className="text-justify ms-3">
          2.1. Podem usufruir dos benefícios contemplados nesta Oferta os
          clientes, tanto pessoas físicas quanto jurídicas, que sejam novos ou
          atuais, e que realizem a adesão no período compreendido entre
          10/07/2024 e 31/07/2024. Esta oferta é válida exclusivamente para o
          município de Mandirituba/PR, sujeita à disponibilidade técnica
          necessária para a prestação dos serviços.
        </span>
        <span className="text-justify ms-3">
          2.2. A continuidade do período de Oferta está sujeita ao critério
          exclusivo da LOViZ e pode ser prorrogada ou encerrada sem a
          necessidade de notificação prévia.
        </span>
        <span className="text-justify ms-3">
          2.3. As condições promocionais aqui presentes não são cumulativas com
          outras Ofertas, a menos que expressamente previsto nos Regulamentos
          correspondentes. Ao tornar-se elegível e optar por esta Oferta, o
          CLIENTE reconhece que poderá perder os benefícios da Promoção anterior
          à qual estava vinculado, caso esta não seja cumulativa com a presente.
        </span>
        <span className="text-justify ms-3">
          2.4. No caso de a LOViZ decidir pela descontinuação desta oferta, seu
          encerramento será devidamente comunicado com 30 (trinta) dias de
          antecedência.
        </span>
        <span className="text-justify ms-3">
          2.5. A participação na Oferta está sujeita à consulta e aprovação de
          crédito.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          3. OPÇÕES DE SERVIÇOS, PACOTES/FRANQUIA, ADESÃO E BENEFÍCIOS
        </span>
        <span className="text-justify ms-3">
          3.1. A LOViZ oferece os mesmos planos detalhados neste documento sem a
          necessidade de compromisso de fidelização. O CLIENTE tem total
          liberdade para decidir se deseja aderir à Oferta com ou sem a
          obrigação de permanência mínima.
        </span>
        <span className="text-justify ms-3">
          3.2. Os valores promocionais a seguir estão em vigor pelo período
          indicado, conforme descrito no item 5, e são exclusivamente destinados
          aos CLIENTES elegíveis, como definido no item 2.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <div class="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">NOME DA OFERTA</th>
                <th scope="col">SERVIÇOS DIGITAIS (SVA) INCLÚSOS</th>
                <th scope="col">PLANO TELEFONE FIXO INCLÚSO</th>
                <th scope="col">
                  PREÇO MENSAL ORIGINAL SEM OFERTA PROMOCIONAL SEM FIDELIDADE
                </th>
                <th scope="col">
                  PREÇO MENSAL PARA ADESÕES PARTICIPANTES DA OFERTA PROMOCIONAL
                  COM FIDELIDADE²
                </th>
                <th scope="col">
                  VALOR PROMOCIONAL 3 PRIMEIRAS MENSALIDADES COM FIDELIDADE
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="align-middle">
                  600 MEGA + Leitura + Streamings
                </td>
                <td className="align-middle">
                  Conta Outra Vez; LOViZ BOOK by Eulalia; Máquina de Contos;
                  Vamolê
                </td>
                <td className="align-middle">TELEFONE INFINITY¹</td>
                <td className="align-middle">R$205,98</td>
                <td className="align-middle">R$155,98</td>
                <td className="align-middle">R$59,99</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span>Tabela 1 - Quadro de Planos de Serviços</span>
        <span className="fst-italic">
          ¹O plano de telefone fixo "FIXO LOCAL DIGITAL" está sujeito à
          disponibilidade técnica e sua mensalidade está isenta no âmbito da
          oferta atual (600 MEGA + Leitura + Streamings), tanto para opções com
          fidelidade quanto sem fidelidade. Devido à natureza isenta da
          mensalidade deste serviço, em situações de falta de disponibilidade
          técnica e/ou impossibilidade de instalação, o serviço "Telefone
          Infinity" não resultará em descontos ou créditos na mensalidade.
          ²Valores promocionais com fidelidade de 12 (doze) meses.
        </span>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">
                  TAXA DE ADESÃO VALOR NÃO PROMOCIONAL SEM FIDELIDADE
                </th>
                <th scope="col">
                  TAXA DE ADESÃO VALOR PROMOCIONAL COM FIDELIDADE DE 12 MESES
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>R$800,00</td>
                <td>R$0,00</td>
              </tr>
            </tbody>
          </table>
          <span>Tabela 2 - Quadro de Valores de Adesão</span>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <p className="text-justify ms-3">
              <span className="fw-bold">3.3.</span> Os valores mencionados neste
              regulamento são valores brutos e estarão sujeitos a qualquer
              tributo aplicável sobre o objeto deste contrato, bem como a
              eventuais mudanças nas alíquotas dos tributos que atualmente
              incidem. Esses acréscimos serão aplicados automaticamente.
            </p>
            <p className="text-justify ms-3">
              <span className="fw-bold">3.4.</span> Cancelamento da oferta com
              fidelização:
              <ol>
                <li>Inadimplência;</li>
                <li>Descumprimento das obrigações contratuais;</li>
                <li>
                  Adesão a outras promoções não cumulativas com a presente
                  Oferta;
                </li>
                <li>Alteração da fatura digital para a fatura física.</li>
              </ol>
            </p>
            <p className="text-justify ms-3">
              <span className="fw-bold">3.5.</span> Para fins de verificação de
              fraude, a LOViZ, dentro das determinações legais, poderá monitorar
              o consumo, cancelar ou suspender, imediatamente os benefícios
              desta oferta, caso seja constatado consumo ou utilização
              fraudulenta e/ou indevida dos mesmos, incluindo-se, mas não se
              limitando, ao repasse e/ou comercialização de qualquer dos
              serviços contratados.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">4. VALORES E DESCONTOS PROMOCIONAIS</span>
        <span className="text-justify ms-3">
          4.1. O valor da mensalidade sem fidelidade é de R$205,98 (duzentos e
          cinco reais e noventa e oito centavos). Para clientes com fidelidade e
          estando dentro deste período de fidelidade, o valor da mensalidade é
          de R$155,98 (cento e cinquenta e cinco reais e noventa e oito
          centavos).
        </span>
        <span className="text-justify ms-3">
          4.2. Promocionalmente, os clientes que aderirem ao plano com
          fidelidade receberão um desconto nas três primeiras mensalidades,
          pagando R$59,99 (cinquenta e nove reais e noventa e nove centavos) por
          mês durante este período inicial. Após as três primeiras mensalidades,
          o valor será reajustado para R$155,98 (cento e cinquenta e cinco reais
          e noventa e oito centavos).
        </span>
        <span className="text-justify ms-3">
          4.3. A instalação dos serviços contratados somente será realizada
          mediante o pagamento do valor correspondente a primeira mensalidade. O
          pagamento deverá ser efetuado antes da instalação. A não realização do
          pagamento resultará na suspensão da instalação até que o pagamento
          seja realizado. A segunda mensalidade será calculada pro rata die,
          considerando o período entre a data da instalação e o próximo
          fechamento de ciclo da fatura, a qual ocorrerá no próximo dia 24
          (vinte e quatro).
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          5. VIGÊNCIA DA OFERTA PROMOCIONAL COM FIDELIDADE
        </span>
        <span className="text-justify ms-3">
          5.1. O preço promocional com fidelidade será vigorará da 1º (primeira)
          até a 12ª (décima segunda) mensalidade.
        </span>
        <span className="text-justify ms-3">
          5.1.1. Promocionalmente, os clientes que aderirem ao plano com
          fidelidade, a 1ª (primeira), 2ª (segunda) e 3ª (terceira) mensalidade
          terão um valor promocional extra, de R$59,99 conforme cláusula 4.2.
        </span>
        <span className="text-justify ms-3">
          5.2. Após o cumprimento do período de 12 (doze) meses, o valor mensal
          poderá retornar ao montante original do plano de serviço sem
          fidelidade, conforme Tabela 1, sem a necessidade de aviso prévio. A
          continuidade do preço promocional nos meses subsequentes ficará a
          critério da LOViZ.
        </span>
        <span className="text-justify ms-3">
          5.3. Os termos deste Regulamento de Oferta Promocional podem ser
          modificados, sendo sempre disponibilizada a versão mais atualizada no
          site da LOViZ, acessível em www.loviz.com.vc
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          6. REGRAS DA PRESENTE OFERTA PROMOCIONAL
        </span>
        <span className="text-justify ms-3">
          6.1. O Contrato de Prestação dos Serviços de Comunicação Multimídia
          (SCM) e Contrato de Serviço Telefônico Fixo Comutado (STFC), são
          aplicáveis a presente Oferta, sendo eles diretamente vinculados a este
          regulamento, e podem ser acessados a qualquer momento pelo CLIENTE
          através dos nossos canais de atendimento e pelo site www.loviz.com.vc
        </span>
        <span className="text-justify ms-3">
          6.2. Os serviços promocionais mencionados neste documento são
          estritamente destinados ao uso privado e interno do CLIENTE, seja ele
          pessoa física ou jurídica. Fica expressamente proibida a
          redistribuição, revenda, compartilhamento ou transferência do serviço
          a terceiros ou para fora das instalações do CLIENTE. Sob nenhuma
          circunstância, os serviços poderão ser convertidos em moeda corrente
          ou para a utilização em serviços prestados por outra operadora.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          7. DOS SERVIÇOS DE VALOR ADICIONADO (SVA)
        </span>
        <span className="text-justify ms-3">
          7.1. Esta oferta inclui Serviços de Valor Adicionado (SVA). O custo
          desses serviços está incorporado ao preço total da oferta e será
          detalhado no extrato demonstrativo de pagamento e cobrança.
        </span>
        <div className="container">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">SERVIÇO SVA</th>
                <th scope="col">VALOR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Contra Outra Vez</td>
                <td>R$20,00</td>
              </tr>
              <tr>
                <td>LOViZ BOOK by Eulalia</td>
                <td>R$11,99</td>
              </tr>
              <tr>
                <td>Máquina de Contos</td>
                <td>R$15,00</td>
              </tr>
              <tr>
                <td>Vamolê</td>
                <td>R$25,00</td>
              </tr>
              <tr>
                <td>MAX</td>
                <td>R$34,90</td>
              </tr>
              <tr>
                <td>Watch + Paramount</td>
                <td>R$1,09</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-12">
              <span className="d-block">Tabela 3 – Quadro de Valores SVA</span>
            </div>
          </div>
        </div>
        <span className="text-justify ms-3">
          7.2. Dado que se trata de uma oferta conjunta, o cancelamento de
          qualquer serviço acarretará no cancelamento integral da oferta, o que
          inclui tanto a Ultra Banda Larga LOViZ quanto quaisquer Serviços de
          Valor Adicionado (SVA) associados.
        </span>
        <span className="text-justify ms-3">
          7.3. Recomenda-se que o download e o uso dos aplicativos de SVA sejam
          feitos por meio do WiFi da LOViZ instalado no endereço do cliente, a
          fim de evitar o consumo de dados da franquia do celular.
        </span>
        <span className="text-justify ms-3">
          7.4. Caso o Cliente opte por realizar o download e/ou uso dos
          aplicativos via internet da operadora de celular, o consumo de dados
          para essas finalidades será descontado normalmente da franquia de
          dados do Cliente. A LOViZ não assume responsabilidade por reembolsos,
          descontos ou créditos relacionados a esse consumo.
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          8. DO SERVIÇO DE ULTRA BANDA LARGA LOViZ
        </span>
        <span className="text-justify ms-3">
          8.1. A presente oferta prevê os seguintes benefícios:
          <br />
          i. Concessão de desconto tal como demonstrado nas Tabelas 1 e 2, que
          estão presentes neste documento.
          <br />
          ii. Concessão em regime de comodato do equipamento óptico integrado ao
          roteador (necessária a utilização do serviço) já contemplando
          funcionalidade de Wi-Fi com conectividade 2.4GHz e 5GHz, aqui
          designado como EQUIPAMENTO.
        </span>
        <span className="text-justify ms-3">
          A experiência de uso dos serviços está condicionada às características
          dos equipamentos e terminais de propriedade do cliente, devendo estes
          apresentarem desempenho de processamento e comunicação compatíveis com
          o EQUIPAMENTO cedido em comodato e com o plano contratado.
        </span>
        <span className="text-justify ms-3">
          O EQUIPAMENTO cedido em comodato tem desempenho de roteamento adequado
          para até 5 (cinco) dispositivos simultâneos. A utilização de mais
          dispositivos simultâneos poderá interferir na velocidade e qualidade
          da conexão.
        </span>
        <span className="text-justify ms-3">
          Os planos de Ultra Banda Larga LOViZ, possuem as seguintes
          características dentro da oferta PROMOÇÃO 600 MEGA + LEITURA +
          STREAMINGS:
        </span>
        <div className="container">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">DOWNLOAD MÁXIMO</th>
                <th scope="col">UPLOAD MÁXIMO</th>
                <th scope="col">VALOR MENSAL SEM FIDELIDADE</th>
                <th scope="col">VALOR MENSAL COM FIDELIDADE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>600mbps</td>
                <td>300mbps</td>
                <td>R$98,00</td>
                <td>R$48,00</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-12">
              <span className="d-block">
                Tabela 4 – Características do Plano Ultra Banda Larga
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">9. REGRAS DO COMODATO DE EQUIPAMENTO</span>
        <span className="text-justify ms-3">
          9.1. O CLIENTE concorda em ficar responsável pela posse e integridade
          do(s) EQUIPAMENTO(S) necessário(s) à prestação do serviço aderido.
          O(s) EQUIPAMENTO(S) será(ão) fornecido(s) pela LOViZ em caráter de
          comodato e o CLIENTE está ciente e concorda em devolvê-lo(s), por
          ocasião da extinção ou cancelamento do Contrato, a qualquer título,
          nas mesmas condições de funcionamento, ressalvado o desgaste natural
          de uso, nos termos definidos na Cláusula Quinta do Contrato de
          Prestação do Serviços de Comunicação Multimídia (SCM).
        </span>
        <span className="text-justify ms-3">
          9.2. O CLIENTE é responsável por utilizar adequadamente o EQUIPAMENTO
          fornecido pela LOViZ e zelar por sua integridade, comprometendo-se a:
          (i) não realizar, nem permitir que terceiros não identificados pela
          LOViZ façam quaisquer intervenções ou inspeções no(s) equipamento(s);
          (ii) alterar qualquer característica original da instalação, sem
          prévia autorização da LOViZ, conforme o caso; (iii) efetuar qualquer
          espécie de reparo, manutenção ou abertura do EQUIPAMENTO para
          quaisquer fins (iv) comunicar a LOViZ a existência de quaisquer
          defeitos ou de anomalias; (v) manter o(s) EQUIPAMENTO(S) no local
          original de sua instalação, considerando-se tais ocorrências como
          falta grave ensejadora de imediata rescisão desta Oferta Promocional e
          demais sanções previstas no Contrato de Prestação do Serviços de
          Comunicação Multimídia (SCM) e no Contrato de Serviço Telefônico Fixo
          Comutado (STFC).
        </span>
        <span className="text-justify ms-3">
          9.3. O CLIENTE declara-se ciente de que, em toda e qualquer hipótese,
          a manutenção do EQUIPAMENTO cedido em comodato deverá ser feita
          exclusivamente por empregados da LOViZ, conforme o caso, ou por
          terceiros por esta autorizados.
        </span>
        <span className="text-justify ms-3">
          9.4. O CLIENTE não poderá emprestar, ceder e/ou sublocar, total ou
          parcialmente, o EQUIPAMENTO cedido em comodato.
        </span>
        <span className="text-justify ms-3">
          9.5. Em casos de danos causados ao EQUIPAMENTO por quaisquer motivos,
          incluindo, mas não se limitando às hipóteses de (i) falta de
          infraestrutura adequada; (ii) perda; (iii) roubo; (iv) furto; (v)
          incêndio; e (vi) utilização e/ou conservação indevidas, o cliente
          sujeitar-se-á ao pagamento da multa no valor vigente referente ao
          equipamento, conforme disposto na Tabela 5 do presente Regulamento.
        </span>
        <span className="text-justify ms-3">
          9.6. Qualquer que seja a hipótese de rescisão do presente contrato, o
          cliente deverá solicitar à Central de Atendimento a desconexão do
          EQUIPAMENTO objeto de comodato, disponibilizando-o para imediata
          devolução e retirada, pela LOViZ, no endereço de instalação do
          assinante, no mesmo estado em que o recebeu quando da ativação dos
          serviços, ressalvando-se tão somente o desgaste natural decorrente do
          uso normal e adequado.
        </span>
        <span className="text-justify ms-3">
          9.7. O CLIENTE declara-se ciente de que deverá estar disponível para
          receber os técnicos da LOViZ na data e período agendados para a visita
          de retirada do EQUIPAMENTO e que, em não sendo possível sua presença,
          deverá tomar as providências necessárias para que terceiros por ele
          autorizados permitam e presenciem a visitação, sob pena de arcar com
          os custos decorrentes da visita técnica improdutiva, sendo que tais
          custos estão descritos na TABELA DE PREÇOS que pode ser consultada no
          site: www.loviz.com.vc.
        </span>
        <span className="text-justify ms-3">
          9.8. A desconexão do EQUIPAMENTO será realizada apenas e
          exclusivamente por técnicos habilitados pela LOViZ, conforme o caso,
          que verificarão o seu estado de conservação e funcionamento no ato da
          retirada do EQUIPAMENTO.
        </span>
        <span className="text-justify ms-3">
          9.9. Caso a LOViZ não consiga retirar o(s) EQUIPAMENTO(S) no endereço
          de instalação por culpa do CLIENTE, o mesmo ficará responsável pela
          entrega do(s) EQUIPAMENTO(S) à LOViZ.
        </span>
        <span className="text-justify ms-3">
          9.9.1 O CLIENTE será notificado, via Central de Atendimento, carta,
          e-mail, SMS, WhatsApp ou qualquer outro meio, acerca de procedimento
          alternativo de devolução, que deverá ocorrer no prazo de 30 (trinta)
          dias, correndo às suas expensas eventuais custos decorrentes.
        </span>
        <span className="text-justify ms-3">
          9.9.1.1. O(s) equipamento(s) disponibilizados pela LOViZ deverão ser
          entregues em bom estado, em perfeito funcionamento e íntegros.
        </span>
        <span className="text-justify ms-3">
          9.9.1.2. O CLIENTE sujeitar-se-á ao pagamento da multa no valor
          vigente referente ao equipamento conforme disposto na Tabela 5 caso:
          (i) sejam constatadas avarias e/ou adulterações no EQUIPAMENTO quando
          da devolução; (ii) retenha o EQUIPAMENTO impossibilitando a sua
          retirada pela LOViZ ou ainda não efetue a devolução conforme item
          5.3.5.8.1. após o decurso do prazo de 30 (trinta) contato do
          recebimento da notificação prevista no item 5.3.5.8.1.1.
        </span>
        <div className="container">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">EQUIPAMENTO</th>
                <th scope="col">VALOR</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>ONT Dual Band AC</td>
                <td>R$450,00 (quatrocentos e cinquenta reais)</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-12">
              <span className="d-block">Tabela 5 - Tabela de Valores ONT</span>
            </div>
          </div>
        </div>
        <span className="text-justify ms-3">
          9.9.1.3. Não havendo o pagamento da multa descrita na Tabela 5 nos
          prazos convencionados, a LOViZ poderá utilizar de todos os meios
          legais disponíveis para a exigência e a cobrança do respectivo valor,
          incluindo, mas não se limitando, a possibilidade de inclusão dos dados
          do cliente em órgãos de proteção ao crédito, como SCPC, SPC, SERASA e
          outros.
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          10. CARACTERÍSTICAS DE ENDEREÇAMENTO E RESTRIÇÕES
        </span>
        <span className="text-justify ms-3">
          10.1. Os planos de serviços descritos neste documento, são ofertados
          mediante a distribuição de endereço IPv6 e endereço IPv4 privado com
          uso por meio de tecnologia CGNAT (Carrier Grade Address Translation) e
          variável (IP não fixo), sem possibilidade de conexões entrantes ou
          hospedagem de serviços nas dependências do cliente ou uso de
          aplicações que exijam o mapeamento de portas TCP/UDP entre endereços
          IPv4 públicos e privados.
        </span>
        <span className="text-justify ms-3">
          10.2. O serviço ofertado não suporta aplicações que necessitem de
          mapeamento de portas TCP/UDP entre o endereço IPv4 público
          compartilhado via CGNAT e o endereço IPv4 privado fornecido ao
          cliente. Entre as aplicações não suportadas são alguns modelos de DVR
          (Digital Video Recorder) de sistemas de vigilância e VPNs (Virtual
          Private Networks) IPSec. Para estes casos, propõe-se a adoção de
          aplicações compatíveis com o protocolo IPv6 ou contratação de um
          serviço específico para este fim.
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          11. DO SERVIÇO DE TELEFONIA FIXA DA LOViZ
        </span>
        <span className="text-justify ms-3">
          11.1. A ativação da linha de telefone é isenta e opcional por parte do
          CLIENTE, dentro dos planos de serviço aqui apresentados. Por se tratar
          de cortesia oferecida pela LOViZ, nesta modalidade de plano
          promocional a não ativação do SERVIÇO TELEFÔNICO FIXO COMUTADO (STFC)
          não acarreta desconto na mensalidade do plano ao CLIENTE que aderir à
          presente Oferta Promocional.
        </span>
        <span className="text-justify ms-3">
          11.2. A linha telefônica da LOViZ deverá ser instalada no mesmo
          endereço da Ultra Banda Larga, cadastrado no Termo de Adesão.
        </span>
        <span className="text-justify ms-3">
          11.3. Para que a linha de telefone seja ativada, o CLIENTE, após ou
          durante a instalação do serviço de internet, deverá solicitar a LOViZ,
          uma das opções a seguir:
          <ul className="ms-3">
            <li>Liberação de um novo código de acesso (número de telefone);</li>
            <li>
              Portabilidade de seu número fixo existente, conforme normas e
              regulamentos vigentes.
            </li>
          </ul>
          11.3.1 A ativação da linha está condicionada a disponibilidade
          técnica, levando em consideração, entre outros fatores, a lotação da
          estrutura tecnológica da LOViZ.
        </span>
        <span className="text-justify ms-3">
          11.4. O plano TELEFONE INFINITY, está incluso nos planos das ofertas
          apresentadas neste documento, é isento de mensalidade e possui uma
          franquia ilimitada para FIXO LOCAL de qualquer operadora.
        </span>
        <span className="text-justify ms-3">
          11.5. A não solicitação da ativação da linha telefônica por parte do
          CLIENTE, não concede qualquer tipo de desconto sob o valor total da
          mensalidade da oferta/plano, uma vez que sua ativação é opcional e
          isenta de mensalidade.
        </span>
        <span className="text-justify ms-3">
          11.5.1 O CLIENTE está ciente de que, o TELEFONE INFINITY é uma
          cortesia concedida pela LOViZ dentro dos planos de serviço aqui
          apresentado, de modo que, caso opte pela ativação da linha telefônica
          fixa e a mantenha inativa por um período igual ou superior a 60
          (sessenta) dias, sem chamadas entrantes ou saítes, a LOViZ poderá
          proceder com o cancelamento da mesma, mediante comunicação prévia.
        </span>
        <span className="text-justify ms-3">
          11.5.2 Considerando o disposto no item anterior, se após decorridos 30
          (trinta) dias contados da comunicação prévia a linha telefônica do
          CLIENTE se mantiver inativa, a LOViZ poderá proceder com o
          cancelamento da mesma.
        </span>
        <span className="text-justify ms-3">
          11.5.2.1 Uma vez cancelada a linha telefônica por inatividade, o
          CLIENTE poderá requerer uma nova ativação.
        </span>
        <span className="text-justify ms-3">
          11.6. Seguem abaixo as chamadas e os principais serviços não incluídos
          no plano TELEFONE INFINITY. Outros serviços podem ser consultados na
          Central de Atendimento da LOViZ.
          <ul className="ms-3">
            <li>Chamadas de qualquer origem e operadora recebidas a cobrar;</li>
            <li>Chamadas de longa distância para fixo;</li>
            <li>Chamadas local e longa distância para celular;</li>
            <li>Chamadas internacionais;</li>
            <li>Chamadas destinadas a 0300, 0500 e 0900;</li>
            <li>
              Chamadas destinadas a serviços interativos, siga-me, chamadas em
              conferência, portais de voz, provedores de internet discada e
              chat;
            </li>
          </ul>
        </span>

        <span className="text-justify ms-3">
          11.6.1 As tarifas excedentes e todas as demais chamadas acima não
          contempladas na presente promoção, serão tarifadas por minuto,
          conforme a tabela abaixo. Outros preços e tarifa vigentes poderão ser
          consultados através da Central de Atendimento LOViZ.
        </span>
        <div className="container">
          <table className="table table-bordered">
            <thead className="thead-dark">
              <tr>
                <th scope="col">ESTRUTURA TARIFÁRIA</th>
                <th scope="col">VALOR POR MINUTO</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Fixo-Fixo LND (Ligações para Fixo de outro DDD)</td>
                <td>R$ 0,11</td>
              </tr>
              <tr>
                <td>Fixo-Móvel Local (Ligações para celulares do mesmo DDD)</td>
                <td>R$ 0,36</td>
              </tr>
              <tr>
                <td>Fixo-Móvel LDN (Ligações para celulares de outro DDD)</td>
                <td>R$ 0,49</td>
              </tr>
              <tr>
                <td>
                  Fixo-Internacional (Ligações para telefones fora do Brasil)
                </td>
                <td>R$1,18</td>
              </tr>
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-12">
              <span className="d-block">
                Tabela 6 – Estrutura tarifária telefone fixo
              </span>
            </div>
          </div>
        </div>
        <span className="text-justify ms-3">
          11.7. A LOViZ, dentro das determinações legais e normativas
          aplicáveis, poderá suspender ou cancelar, imediatamente o Plano de
          Serviço ao qual o CLIENTE estiver vinculado, caso constate consumo ou
          utilização inadequada. Dentre outros aspectos, poderá ser considerado
          uso inadequado:
          <ul className="ms-3">
            <li>Comercialização de minutos/serviços;</li>
            <li>
              Utilização da linha em equipamentos diferente do instalado pela
              LOViZ, tais como PABX e equipamentos similares;
            </li>
            <li>Limite de 20 (vinte) números diferentes de destino por dia;</li>
            <li>
              Limite de 5 (cinco) horas de ligações locais para outros fixos por
              dia;
            </li>
            <li>Limite de 10.000 (dez mil) minutos de chamadas LDN;</li>
            <li>
              Utilização com características de uso industrial, ou seja, volume
              de tráfego incompatível com a possibilidade de utilização diária
              humana;
            </li>
            <li>
              Alto volume de tráfego para outras operadoras (e/ou espelho) com o
              objetivo de receber benefícios e repasses de tráfego;
            </li>
            <li>
              Concentração do serviço siga-me, em um único terminal que faz
              chamadas off net;
            </li>
            <li>
              Desconhecimento por parte do CLIENTE da solicitação do serviço.
            </li>
          </ul>
        </span>
        <span className="text-justify ms-3">
          11.8. Para adesão à promoção, caso o CLIENTE LOViZ já possua o
          telefone fixo da LOViZ, este deve estar ativo na base de clientes da
          LOViZ, ser da mesma área de registro da linha LOViZ e estar adimplente
          com a LOViZ.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">12. REGRAS DE COBRANÇA</span>
        <span className="text-justify ms-3">
          12.1. Os serviços incluídos na Oferta, serão faturados e demonstrados
          em documento de cobrança digital, estando disponíveis através do
          portal do cliente www.minhaloviz.com.vc
        </span>
        <span className="text-justify ms-3">
          12.2. Os pagamentos poderão ser efetuados através da rede bancária
          autorizada pela LOViZ, bastando para isso emitir o boleto digital no
          portal do cliente.
        </span>
        <span className="text-justify ms-3">
          12.3. As faturas serão enviadas digitalmente, todos os meses, via
          e-mail e SMS e/ou WhatsApp do CLIENTE. O não recebimento da fatura
          pelo CLIENTE não o isenta do devido pagamento.
        </span>
        <span className="text-justify ms-3">
          12.3.1 O recebimento digital, citado no item 6.3, depende de prévia
          autorização por parte do CLIENTE, a ser realizado no TERMO DE ADESÃO,
          juntamente com a correta informação e/ou atualização de seus dados
          cadastrais.
        </span>
        <span className="text-justify ms-3">
          12.3.2 É de responsabilidade do CLIENTE manter seus dados sempre
          atualizados junto a LOViZ.
        </span>
        <span className="text-justify ms-3">
          12.4. As ofertas aqui apresentadas, são exclusivamente com envio de
          fatura digital. Caso o CLIENTE deseje receber sua fatura
          impressa/física, propõe-se a contratação de oferta que inclua esta
          opção ou estará sujeito ao cancelamento dos benefícios oferecidos na
          presente Oferta, conforme mencionado no item 9.1.7
        </span>
        <span className="text-justify ms-3">
          12.5. Os planos de serviço e, consequentemente, os preços mensais como
          o desconto, serão reajustados nas condições estabelecidas no Contrato
          de Prestação dos serviços de SCM e STFC, aplicando-se sobre os valores
          reajustados o mesmo percentual de desconto que utilizando na indicação
          do benefício.
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          13. OUTRAS INFORMAÇÕES E RESTRIÇÕES À UTILIZAÇÃO DAS OFERTAS DE
          SERVIÇO
        </span>
        <span className="text-justify ms-3">
          13.1. A adesão do CLIENTE à Oferta ocorrerá mediante solicitação junto
          à Central de Atendimento e/ou agentes autorizados de venda pela LOViZ.
        </span>
        <span className="text-justify ms-3">
          13.2. Para usufruir de uma das Ofertas, o CLIENTE deverá cumprir e
          concordar com todas as obrigações constantes do presente documento,
          bem como com as disposições dos contratos de prestação de serviços e
          demais instrumentos associados. O CLIENTE deverá estar adimplente com
          a LOViZ. Em caso de inadimplência, a Oferta poderá ser definitivamente
          excluída, voltando o CLIENTE a usufruir do Plano na forma original,
          conforme valores apresentados na Tabela 1, ou seja, perdendo o valor
          mensal da Oferta Promocional.
        </span>
        <span className="text-justify ms-3">
          13.3. O CLIENTE deverá permanecer no Plano elegível durante o período
          de fruição da Oferta, caso o CLIENTE solicite qualquer alteração ou
          migração de Plano de Serviço, especialmente no caso de downgrade
          (redução de velocidade ou valor), perderá os benefícios da presente
          Oferta, ainda que para um Plano elegível.
        </span>
        <span className="text-justify ms-3">
          13.4. Caso o CLIENTE solicite o cancelamento, troca de plano ou troca
          de titularidade, estará automaticamente desligado da Promoção,
          deixando de usufruir os benefícios decorrentes desta.
        </span>
        <span className="text-justify ms-3">
          13.5. Os CLIENTES elegíveis estão sujeitos a análise, limite e regras
          de crédito da LOViZ.
        </span>
        <span className="text-justify ms-3">
          13.6. Esta Oferta é destinada a pessoa física ou jurídica, com CPF ou
          CNPJ, devendo o CLIENTE ser o destinatário final do serviço prestado
          pela LOViZ.
        </span>
        <span className="text-justify ms-3">
          13.7. A velocidade do serviço de Ultra Banda Larga contratada está
          sujeita à verificação técnica no ato da instalação. Havendo
          impossibilidade técnica para instalação da velocidade contratada, o
          CLIENTE poderá requerer a redução da velocidade ou cancelar seu
          pedido, sem ônus.
        </span>
        <span className="text-justify ms-3">
          13.8. Em caso de ser identificado mau uso da Oferta Promocional pelo
          CLIENTE, a LOViZ poderá, a seu exclusivo critério, reduzir a concessão
          dos benefícios ou mesmo excluí-los definitivamente, voltando o CLIENTE
          ao Plano original sem eventuais promoções aplicáveis.
        </span>
        <span className="text-justify ms-3">
          13.8.1 O CLIENTE que for retirado da Oferta Promocional por mau uso
          e/ou fraude, ou por qualquer outro motivo que infrinja este
          regulamento, ficará impedido de se cadastrar em qualquer outra Oferta
          Promocional pelo período de 180 (cento e oitenta) dias, a exclusivo
          critério da LOViZ.
        </span>
        <span className="text-justify ms-3">
          13.9. Considera-se mau uso pelo CLIENTE, a prática de qualquer fraude
          ou desvio na utilização dos serviços promocionais, incluindo o uso de
          equipamentos eletrônicos não autorizados e o compartilhamento e/ou
          venda do seu sinal.
        </span>
        <span className="text-justify ms-3">
          13.10. As velocidades de navegação informados na Tabela 4 seguem as
          resolução e diretrizes da ANATEL.
        </span>
        <span className="text-justify ms-3">
          13.11. As velocidades indicadas na Tabela 4 podem variar de acordo com
          a taxa de utilização da rede e está sujeita a variação de fatores
          externos, alheios a vontade da LOViZ.
        </span>
        <span className="text-justify ms-3">
          13.12. É obrigação do CLIENTE garantir que a instalação dos
          equipamentos necessários à prestação dos serviços não será impedida
          por obstáculos físicos que comprometam a conclusão dos trabalhos.
        </span>
        <span className="text-justify ms-3">
          13.13. Caso não seja possível a instalação do plano de serviço
          contratado junto a LOViZ no endereço solicitado pelo CLIENTE, por
          qualquer motivo, o CLIENTE perderá o direito de participar da Oferta
          Promocional, isentando a LOViZ de qualquer responsabilidade.
        </span>
        <span className="text-justify ms-3">
          13.14. O CLIENTE fica ciente que a contratação dos planos e serviços
          apresentados neste documento é para uso pessoal e que é vedada a
          comercialização dos serviços contratados, declarando estar ciente
          ainda de que o desenvolvimento de atividades de telecomunicações sem a
          necessária autorização expedida pela Anatel constitui crime e multa de
          R$10.000,00 (dez mil reais) prevista no art. 131 e 183, da Lei
          9.472/97.
        </span>
        <span className="text-justify ms-3">
          13.15. A LOViZ não será responsabilizada por qualquer ocorrência ou
          impossibilidade de cumprimento da presente Oferta que seja decorrente
          de fatores externos, alheios a sua vontade, em especial caso fortuito
          ou de força maior.
        </span>
        <span className="text-justify ms-3">
          13.16. Serão permitidas até 3 (três) ativações na presente Oferta para
          a mesma pessoa física (CPF) ou jurídica (CNPJ).
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          14. PRAZO DE PERMANÊNCIA, PERÍODO E VALOR DA MULTA POR RESCISÃO
          ANTECIPADA
        </span>
        <span className="text-justify ms-3">
          14.1. Tendo em vista o desconto oferecido nos planos e taxa de adesão,
          se, em até 12 (doze) meses da adesão à Oferta, houver, por parte do
          CLIENTE, solicitação de cancelamento, migração para velocidade
          inferior, ou troca de titularidade, o CLIENTE deverá pagar multa no
          valor de R$800,00 (oitocentos reais). O valor da multa a ser cobrada é
          proporcional (pro-rata) ao período que faltar para completar 12 (doze)
          meses contados da adesão, perdendo ainda, imediatamente, o direito aos
          benefícios desta Oferta.
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          15. ENCERRAMENTO DA OFERTA E CESSÃO DE BENEFÍCIOS
        </span>
        <span className="text-justify ms-3">
          15.1. O CLIENTE deixará de usufruir dos benefícios das Ofertas, em
          caso de:
          <ul className="text-justify ms-3">
            <li>Término da Oferta;</li>
            <li>Cancelamento do serviço contratado;</li>
            <li>Extinção do plano ou serviço contratado;</li>
            <li>Inadimplência;</li>
            <li>Transferência de titularidade do serviço contratado;</li>
            <li>Descumprimento das obrigações contratuais;</li>
            <li>Mudança da fatura digital para fatura impressa/física;</li>
            <li>
              Adesão a outras promoções não cumulativas com a presente Oferta;
            </li>
            <li>
              Mudança de endereço para qualquer cidade não prevista no anexo 1
              e/ou sem cobertura da rede da LOViZ;
            </li>
            <li>
              Cancelar ou suspender temporariamente qualquer dos planos
              necessários ao funcionamento desta promoção ou de qualquer de seus
              dependentes.
            </li>
          </ul>
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">
          16. DA PROPRIEDADE INTELECTUAL E PROTEÇÃO DE DADOS
        </span>
        <span className="text-justify ms-3">
          16.1. O CLIENTE declara e reconhece que a titularidade e os direitos
          relativos à toda e qualquer propriedade intelectual da LOViZ pertencem
          exclusivamente a essa. O acesso, pelo CLIENTE à estrutura, sistemas e
          plataformas, bem como à demais criações/projetos da LOViZ, não lhe
          conferem qualquer direito ou prerrogativa sobre propriedade
          intelectual. Ainda, na hipótese de violação, pelo CLIENTE, dos
          direitos relativos à propriedade intelectual da LOViZ, o CLIENTE
          ficará sujeito ao pagamento de indenização até a altura dos danos
          efetivamente causados à LOViZ, sem prejuízo das penalidades previstas
          na legislação em vigor.
        </span>
        <span className="text-justify ms-3">
          16.2. Em função das atividades estabelecidas neste Instrumento, a
          LOViZ declara estar ciente de suas obrigações e de acordo com a Lei n.
          13.709/2018 (Lei Geral de Proteção de Dados), uma vez que poderá ter
          acesso a dados pessoais de seus CLIENTES que são pessoas físicas e/ou
          de outras pessoas físicas relacionadas. Deste modo, a LOViZ se tornará
          responsável pelo tratamento adequado dessas informações, assim como
          pela adoção de medidas técnicas e de segurança aptas a proteger os
          dados pessoais.
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">17. FORO</span>
        <span className="text-justify ms-3">
          17.1. O presente Regulamento encontra-se disponível na versão
          atualizada no site www.loviz.com.vc e obriga herdeiros e/ou
          sucessores, sendo eleito o foro do domicílio do CLIENTE participante
          da promoção como o único competente para dirimir qualquer questão
          oriunda deste Regulamento, com exclusão a qualquer outro, por mais
          privilegiado que seja ou venha a ser.
        </span>
      </div>

      <div className="col-md-12 mt-3 d-flex flex-column gap-2">
        <span className="fw-bold">18. DA ASSINATURA ELETRÔNICA</span>
        <span className="text-justify ms-3">
          18.1. As Partes afirmam e declaram que o presente acordo poderá ser
          assinado por meio eletrônico, nos termos do Art. 10o §2º da MP
          2200-2/2001.
        </span>
      </div>
      <div className="col-md-12 mt-3 d-flex flex-column align-items-center">
        <span className="text-justify ms-3">
          Curitiba-PR, 09 de julho de 2024.
        </span>
      </div>
    </div>
  );
}
