import React, { useEffect } from "react";

import Banner from "../../Components/Banner";
import FormCep from "../../Components/FormCep";
import Tab from "../../Components/Tab";
import FAQ from "../../Components/FAQ";
import ContactUs from "../../Components/ContactUs";

import FirstSection from "../../Parts/SuperWifi/FirstSection/FirstSection";
import MeshNetwork from "../../Parts/SuperWifi/MeshNetwork";
import SuperWifiSection from "../../Parts/SuperWifi/SuperWifiSection";

import "./style.css";
import BannerImage from "../../assets/img/superwifi.webp";

function Wifi() {
  const faqList = [
    {
      title: "<div>Wi-Fi</div>",
      content: (
        <FAQ
          questions={[
            {
              title: "O que é Wi-Fi Extreme?",
              content:
                "O Wi-Fi Extreme é entregue pelo nosso modem " +
                "de última geração (tecnologia Wi-Fi AC), que proporciona uma" +
                " melhor conexão e experiência. Graças a essa tecnologia, você poderá " +
                "navegar em ultra velocidades, através do Wi-Fi, utilizando seus" +
                " aparelhos que também possuam a tecnologia Wi-Fi AC.\n",
            },
            {
              title: "Qual a difrença entre a rede 2,4 GHz e 5 GHz (Wi-Fi AC)?",
              content:
                "A rede 2.4GHz tem maior alcance, porém sofre mais interferência " +
                "e possui velocidades máximas de 50Mbps dependendo do desempenho do seu" +
                " dispositivo e e cenário que se encontra. Já a rede 5GHz (Wi-Fi AC) tem" +
                " menor alcance, porém suporta velocidades mais altas quando o dispositivo estiver mais próximo " +
                "ao modem Wi-Fi, onde sua experiência de navegação e performance é melhor.\n",
            },
            {
              title: "Como aumentar a cobertura da minha rede Wi-Fi?",
              content:
                "Caso sua casa seja grande ou você queira ampliar o " +
                "sinal do seu Wi-Fi, recomendamos a instalação de repetidores " +
                "Wi-Fi com tecnologia MESH. Para mais informações clique aqui.\n",
            },
            {
              title:
                "Minha conexão Wi-Fi não está boa. O que pode estar acontecendo?",
              content:
                "Verifique a existência de barreiras físicas, pois elas " +
                "podem interferir na sua conexão. Lâmpadas, geladeiras, caixas de som," +
                " paredes, portas, vidros, espelhos são exemplos de barreiras físicas." +
                " Além das barreiras físicas, a distância entre seu dispositivo e o " +
                "modem Wi-Fi podem interferir na qualidade do sinal e consequentemente " +
                "na velocidade de acesso, se sua casa for grande, " +
                "será necessário instalar repetidores Wi-Fi com tecnologia MESH, para mais informações clique aqui.\n",
            },
            {
              title:
                "Em quais aparelhos posso utilizar minha conexão Wi-Fi Fibra?",
              content:
                "Na sua smart TV, celulares, tablet, notebook, consoles de " +
                "jogos, dentre outros. Por isso, a velocidade da internet depende" +
                " da capacidade dos seus aparelhos e também da distância entre esses " +
                "dispositivos e o modem LOViZ. Lembrando que a velocidade é dividida entre todos os outros" +
                " dispositivos conectados (smart TV, celular, tablet etc.) à sua rede Wi-Fi.\n",
            },
            {
              title: "Como fazer a troca de senha Wi-Fi?",
              content:
                "Você pode estar solicitando a troca " +
                "da senha Wi-Fi através de nosso telefone ou WhatsApp <a href='https://api.whatsapp.com/send?phone=4137977777" +
                "&text=Falar%20com%20WhatsApp%20' target='_blank'>41 3797-7777</a>.\n",
            },
          ]}
        />
      ),
    },
  ];
  useEffect(() => {
    const animeItens = document.querySelectorAll("[data-anime]");
    animeItens.forEach((el, index) => {
      setTimeout(() => el.classList.add("active"), index * 100);
    });
  }, []);

  return (
    <div id="super-wifi">
      <Banner
        principalTitle="Sim, o seu Wi-FI<br/>funciona aqui também."
        description="Garanta um Wi-Fi que funcione em toda a sua casa"
        classPrincipalTitleColor="c-white"
        classFontColor="c-white"
        backgroundPosition="left"
        backgroundOverlay="rgba(0, 0, 0, .6), rgba(0, 0, 0, .6)"
        background={BannerImage}
      />
      <FormCep sectionClass="p-0 h-100px mobile-h-150px" />
      <FirstSection />
      <MeshNetwork />
      <div className="pink-tab">
        <br />
      </div>
      <SuperWifiSection />
      <Tab tabs={faqList} />
      <ContactUs
        link={
          "https://api.whatsapp.com/send?phone=4137977777&text=Super%20WiFi"
        }
      />
    </div>
  );
}

export default Wifi;
