import React from "react";
import {Link, NavLink} from "react-router-dom";
import FooterCol from "./FooterCol";
import NewsletterFooter from "./NewsletterFooter";
import Logo from "../../assets/img/LOViZ_LOGO_PRINCIPAL_RGB.png";
import ScriptTag from "react-script-tag";
import "./style.css";

function Footer() {
    return (
        <footer className="mt-30">
            <div className="container">
                <div className="row">
                    <div className="col-md-2">
                        <FooterCol title="Conheça">
                            <ul className={`footer-list mt-20`}>
                                <li>
                                    <Link to="/para-residencias">Para sua Residência</Link>
                                </li>
                                <li>
                                    <Link to="/para-empresas">Para sua Empresa</Link>
                                </li>
                                <li>
                                    <Link to="/para-condominios">Para Condomínios</Link>
                                </li>
                                <li>
                                    <a href="/solucoes#solution-list">Seja um Franqueado</a>
                                </li>
                                <li>
                                    <a href="/solucoes#rede-neutra">Rede Neutra</a>
                                </li>
                                <li>
                                    <a href="/solucoes#atacado">Atacado</a>
                                </li>
                                <li>
                                    <a href="/solucoes#isp">ISP</a>
                                </li>
                                <li>
                                    <a href="/solucoes#swap">SWAP</a>
                                </li>
                                <li>
                                    <a href="/solucoes#projetos-especiais">Projetos especiais</a>
                                </li>
                            </ul>
                        </FooterCol>
                    </div>
                    <div className="col-md-2">
                        <FooterCol title="Produtos">
                            <ul className={`footer-list mt-20`}>
                                <li>
                                    <Link to="/gigabit-internet">Gigabit Internet</Link>
                                </li>
                                <li>
                                    <Link to="/super-wifi">Super Wi-Fi</Link>
                                </li>
                                <li>
                                    <Link to="/telefone">Phone Infinity</Link>
                                </li>
                                {/*<li>
                  <Link to="/45g">4.5G+</Link>
                </li>
                <li>
                  <Link to="/iot">IoT</Link>
                </li>*/}
                                <li>
                                    <Link to="/educacao">Educação</Link>
                                </li>
                            </ul>
                        </FooterCol>
                    </div>
                    <div className="col-md-2">
                        <FooterCol title="Sobre">
                            <ul className={`footer-list mt-20`}>
                                {/*<li>*/}
                                {/*  <a href="#">Blog</a>*/}
                                {/*</li>*/}
                                <li>
                                    <Link to="/a-loviz">A Loviz</Link>
                                </li>
                                <li>
                                    <Link to="/trabalhe-conosco">Carreira</Link>
                                </li>
                                <li>
                                    <Link to="/ecossistema">Ecossistema</Link>
                                </li>
                                <li>
                                    <Link to="/fale-conosco">Suporte</Link>
                                </li>
                                <li>
                                    <Link to="/socio">Investidores</Link>
                                </li>
                                <li>
                                    <a href="https://lovizcomvc.speedtestcustom.com">Teste de Velocidade</a>
                                </li>
                                <li>
                                    <Link to="/compliance">Compliance</Link>
                                </li>
                            </ul>
                        </FooterCol>
                    </div>
                    <div className="col-md-2">
                        <FooterCol title="Minha LOViZ">
                            <ul className={`footer-list mt-20`}>
                                <li>
                                    <a href="https://portal.loviz.com.vc/auth/login">Central do Assinante</a>
                                </li>
                                <li>
                                    <a href="/2-via">2º Via de Conta</a>
                                </li>
                                {/* <li>
                  <Link to="/lojas">Nossas Lojas</Link>
                </li> */}
                                <li>
                                    <Link to="/fale-conosco">FAQ</Link>
                                </li>
                                <li>
                                    <NavLink to="/fale-conosco">Fale conosco</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/trabalhe-conosco">
                                        Trabalhe conosco
                                    </NavLink>
                                </li>
                                <li>
                                    <a href="https://api.whatsapp.com/send?phone=4137977777&text=Assine%20agora"
                                       target="_blank" rel="noreferrer">
                                        Assine agora
                                    </a>
                                </li>
                                <li>
                                    <a href="https://api.whatsapp.com/send?phone=4137977777&text=Suporte%20T%C3%A9cnico"
                                       target="_blank" rel="noreferrer">
                                        Suporte Técnico
                                    </a>
                                </li>
                                <li>
                                    <a href="https://api.whatsapp.com/send?phone=4137977777&text=LOViZ%20Empresas"
                                       target="_blank" rel="noreferrer">
                                        LOViZ Empresas
                                    </a>
                                </li>
                            </ul>
                        </FooterCol>
                    </div>
                    <div className="col-md-2">
                        <FooterCol>
                            <ul className={`footer-list`}>
                                <li>
                                    <a href="/termos-promocionais">
                                        <strong>Termos Promocionais</strong>
                                    </a>
                                </li>
                                <li>
                                    <a href="/politica-privacidade">
                                        <strong>Política de Privacidade</strong>
                                    </a>
                                </li>
                            </ul>
                            <div id="ra-verified-seal">
                                <ScriptTag
                                    type="text/javascript" id="ra-embed-verified-seal"
                                    src="https://s3.amazonaws.com/raichu-beta/ra-verified/bundle.js"
                                    data-id="cm1paXNuRW5aTHBWTk56bzpsb3Zpeg==" data-target="ra-verified-seal"
                                    data-model="2"/>

                            </div>
                        </FooterCol>
                    </div>
                    <div className=" col-md-2 menu-footer newsletter-menu-footer">
                        <FooterCol title=" Formas de contato">
                            <NewsletterFooter/>
                        </FooterCol>
                    </div>
                </div>
                <hr/>
                <div className=" row ptb-20">
                    <div
                        className=" col-12 col-sm-12 col-md-12 col-lg-2 d-flex justify-content-center
                                    justify-content-lg-start mt-10">
                        <Link to="/">
                                <img src={Logo} width="150px"/>
                            </Link>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-10 text-center mt-10">
            <span className="fs-12">
              O nome 'LOViZ' e seu logotipo são marcas registradas da LOViZ S/A
              e estão registrados em várias jurisdições ao redor do mundo.
              ©Copyright {new Date().getUTCFullYear()} LOViZ S/A. Todos os
              direitos reservados.
            </span>
                    </div>
                </div>
            </div>
        </footer>
);
}

export default Footer;
