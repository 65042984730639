import React from "react";
import GIGABIT_INTERNET_PESSOAS from "../../../assets/img/GigabitInternet/00-GIGABIT-INTERNET-PESSOAS.png";

export default function Introduction() {
  return (
    <section className="bg-gray">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <img src={GIGABIT_INTERNET_PESSOAS} alt="" data-anime />
              </div>
            </div>
          </div>
          <div className="col-md-6 align-self-center introduction-first-div">
            <div className="row">
              <div className="col-md-12">
                <h1 className="fs-60 c-dark mobile-fs-35" data-anime>
                  1000 MEGA
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p className="c-gray font-italic fs-15 fw-600" data-anime>
                  Rápido, confiável e projetado para tudo o que você faz.
                  <br />
                  Sem limites de velocidade. 199,99 / mês.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
