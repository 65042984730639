import React, { useState } from "react";
import CustomModal from "../Residence/CustomModal";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function NewPlans({ planTitle = "" }) {
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const plans = [
    {
      color: "blue-plan",
      speed: "600 Megabits",
      value: "99",
      topics: [
        "Sem taxa de instalação*",
        "Suporte 24h todos os dias",
        "Internet Super rápida com 600 Megabits Downloads",
        "Maravilhosa para Jogos Online",
        "Super Wi-Fi Onboard",
        "Internet com Tecnologia FHS (R)",
        "Telefone Infinity*",
        "Digital Books by Skeelo",
        "Audio Books by Skeelo",
      ],
    },
    {
      color: "red-plan",
      speed: "1000 Megabits",
      value: "199",
      topics: [
        "Sem taxa de instalação*",
        "Suporte 24h todos os dias",
        "Internet Ultra rapida com 1000 Megabits Downloads",
        "Inacreditável para Jogos Online",
        "Super Wi-Fi Onboard",
        "Bom para dispositivos de última geração",
        "Telefone Infinity*",
        "Internet com Tecnologia FHS (R)",
        "Digital Books by Skeelo",
        "Audio Books by Skeelo",
        "Streaming LOViZ ViDEO",
        "Instalação Profissional",
        "Antivirus by HERO",
        "Wi-Fi Seguro by HERO",
        "Cloud by HERO",
        "Otimizador de bateria by HERO",
      ],
    },
  ];

  return (
    <section id="company-plans">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="section-title text-center">
              Planos <span className="c-primary">{planTitle}</span>
            </h2>
          </div>
          <div className="row justify-content-center  mt-5">
            {plans.map((plan, key) => (
              <div className="col-12 col-md-6" key={key}>
                <div className="card card-plan shadow-lg">
                  <div className="card-title">
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className={`plan-header-speed text-white fw-bold fs-4 ${plan.color}`}
                      >
                        {plan.speed}
                      </div>
                      <div className="plan-header-value fs-4 fw-bold d-flex p-3">
                        <h2 className="fw-bold">{plan.value}</h2>/ mês
                      </div>
                    </div>
                  </div>

                  <div className="card-body">
                    <ul>
                      {plan.topics.map((topic, index) => (
                        <li key={index} className="plan-list">
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="card-footer bg-white">
                    <div className="d-grid gap-3">
                      <a
                        className=" btn-whatsapp text-white"
                        href="https://api.whatsapp.com/send?phone=4137977777&text=Planos%20Residenciais"
                      >
                        <FontAwesomeIcon
                          icon={faWhatsapp}
                          className="c-white"
                        />
                        {""} Fale conosco
                      </a>
                      {key === 0 ? (
                        <div
                          className="row cursor-pointer"
                          onClick={() => setShowModal(!showModal)}
                        >
                          <div className="col-md-12 text-center mb-20">
                            <span className="fs-18 c-gray font-italic fw-600">
                              + detalhes dessa oferta
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="row cursor-pointer"
                          onClick={() => setShowModal2(!showModal2)}
                        >
                          <div className="col-md-12 text-center mb-20">
                            <span className="fs-18 c-gray font-italic fw-600">
                              + detalhes dessa oferta
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {showModal ? (
                    <CustomModal
                      scrollable={true}
                      title="Detalhes da oferta"
                      setShowModal={setShowModal}
                    >
                      <tr>
                        <td>
                          <div className="d-flex flex-column">
                            <span className="fs-20">
                              LOViZ Gigabit: 600 MEGA
                            </span>
                            <span>600 MEGA + Acesso a LOViZ BOOK </span>
                            <span>99 / mês no Boleto ou Débito em conta</span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Instalação Profissional
                            </span>
                            <span>
                              A oferta escolhida possui instalação profissional
                              de um técnico especializado da LOViZ.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Prazo de Instalação</span>
                            <span>
                              A instalação ocorre em até 7 dias úteis a partir
                              da data de agendamento. De Segunda a Sábado, de
                              08:00 às 20:00.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Equipamento</span>
                            <span>
                              O modem Super Wi-Fi da LOViZ será entregue em
                              comodato mediante solicitação. O cliente pode
                              optar por utilizar o próprio dispositivo.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Velocidade do Plano</span>
                            <span>
                              Essas são as velocidades máximas nominais. A
                              velocidade pode variar devido a fatores externos.
                              Promocionalmente, a franquia é ilimitada.
                              Velocidade contratada é a taxa de transmissão ou
                              velocidade da conexão. É o quão rápido um pacote
                              de dados do seu dispositivo vai ser levado até a
                              operadora e vice-versa. A velocidade do serviço de
                              Internet Gigabit da LOViZ contratada pelo
                              assinante está sujeita a verificação de
                              viabilidade técnica no ato da instalação.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Franquia de Dados</span>
                            <span>A franquia de dados é ilimitada.</span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Garanta uma melhor experiência de navegação
                            </span>
                            <span>
                              Os dispositivos (smartphones, computadores,
                              tablets, etc.) precisam ter as configurações
                              mínimas necessárias para que atinjam o máximo de
                              desempenho, tanto via cabo como via Wi-Fi. O modem
                              é dual-band, ou seja, tem frequência de 2,4 GHz e
                              5 GHz no Wi-Fi. Verifique se os seus dispositivos
                              permitem a navegação em ambas as frequências. A
                              conexão via cabo proporciona maior estabilidade e
                              não sofre tantas interferências quanto a rede
                              Wi-Fi.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Phone Infinity ilimitado
                            </span>
                            <span>
                              Ligações para celular e fixo de qualquer operadora
                              do Brasil. Para realizar chamadas para outro DDD,
                              basta discar 0 + DDD + Número de telefone. De
                              acordo com a Lei 20.089/2019, por determinação do
                              estado do Paraná, o serviço Identificador de
                              Chamadas é gratuito no fixo contratado
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Suporte ao Cliente 24h
                            </span>
                            <span>
                              Conte com nossos especialistas 24h para te ajudar
                              com problemas de tecnologia, tirar dúvidas e
                              realizar diagnósticos sempre que precisar. Ligue e
                              fale com nossos especialistas: 41 3797-7777.
                            </span>

                            <hr color="#000000" />
                            <span className="fs-20">Termos Promocionais</span>
                            <span>
                              Consulte nossos{" "}
                              <a
                                href="/termos-promocionais"
                                className="text-primary"
                              >
                                Termos Promocionais
                              </a>
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Reajuste anual</span>
                            <span>
                              O valor mensal da oferta poderá, a critério da
                              LOViZ, ser reajustada anualmente de acordo com a
                              variação positiva do IGP-DI ou índice geral que o
                              substitua.
                            </span>
                          </div>
                        </td>
                      </tr>
                    </CustomModal>
                  ) : null}
                  {showModal2 ? (
                    <CustomModal
                      scrollable={true}
                      title="Detalhes da oferta"
                      setShowModal={setShowModal2}
                    >
                      <tr>
                        <td>
                          <div className="d-flex flex-column">
                            <span className="fs-20">
                              LOViZ Gigabit: 1000 MEGA
                            </span>
                            <span>1000 MEGA + Acesso a LOViZ ViDEO </span>
                            <span>199 / mês no Boleto ou Débito em conta</span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Instalação Profissional
                            </span>
                            <span>
                              A oferta escolhida possui instalação profissional
                              de um técnico especializado da LOViZ.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Prazo de Instalação</span>
                            <span>
                              A instalação ocorre em até 7 dias úteis a partir
                              da data de agendamento. De Segunda a Sábado, de
                              08:00 às 20:00.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Equipamento</span>
                            <span>
                              O modem Super Wi-Fi da LOViZ será entregue em
                              comodato mediante solicitação. O cliente pode
                              optar por utilizar o próprio dispositivo.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Velocidade do Plano</span>
                            <span>
                              Essas são as velocidades máximas nominais. A
                              velocidade pode variar devido a fatores externos.
                              Promocionalmente, a franquia é ilimitada.
                              Velocidade contratada é a taxa de transmissão ou
                              velocidade da conexão. É o quão rápido um pacote
                              de dados do seu dispositivo vai ser levado até a
                              operadora e vice-versa. A velocidade do serviço de
                              Internet Gigabit da LOViZ contratada pelo
                              assinante está sujeita a verificação de
                              viabilidade técnica no ato da instalação.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Franquia de Dados</span>
                            <span>A franquia de dados é ilimitada.</span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Garanta uma melhor experiência de navegação
                            </span>
                            <span>
                              Os dispositivos (smartphones, computadores,
                              tablets, etc.) precisam ter as configurações
                              mínimas necessárias para que atinjam o máximo de
                              desempenho, tanto via cabo como via Wi-Fi. O modem
                              é dual-band, ou seja, tem frequência de 2,4 GHz e
                              5 GHz no Wi-Fi. Verifique se os seus dispositivos
                              permitem a navegação em ambas as frequências. A
                              conexão via cabo proporciona maior estabilidade e
                              não sofre tantas interferências quanto a rede
                              Wi-Fi.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Phone Infinity ilimitado
                            </span>
                            <span>
                              Ligações para celular e fixo de qualquer operadora
                              do Brasil. Para realizar chamadas para outro DDD,
                              basta discar 0 + DDD + Número de telefone. De
                              acordo com a Lei 20.089/2019, por determinação do
                              estado do Paraná, o serviço Identificador de
                              Chamadas é gratuito no fixo contratado
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              Suporte ao Cliente 24h
                            </span>
                            <span>
                              Conte com nossos especialistas 24h para te ajudar
                              com problemas de tecnologia, tirar dúvidas e
                              realizar diagnósticos sempre que precisar. Ligue e
                              fale com nossos especialistas: 41 3797-7777.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">
                              LOViZ ViDEO: o serviço de streaming da LOViZ
                            </span>
                            <span>
                              Assista séries, filmes, esportes e desenhos quando
                              quiser, através do seu computador, celular ou
                              tablet. Com a LOViZ ViDEO, você tem conteúdo do
                              seu jeito e diversão para toda a família.
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Termos Promocionais</span>
                            <span>
                              Consulte nossos{" "}
                              <a
                                href="/termos-promocionais"
                                className="text-primary"
                              >
                                Termos Promocionais
                              </a>
                            </span>
                            <hr color="#000000" />
                            <span className="fs-20">Reajuste anual</span>
                            <span>
                              O valor mensal da oferta poderá, a critério da
                              LOViZ, ser reajustada anualmente de acordo com a
                              variação positiva do IGP-DI ou índice geral que o
                              substitua.
                            </span>
                          </div>
                        </td>
                      </tr>
                    </CustomModal>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
