import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

import Logo from "../../assets/img/LOViZ_LOGO_PRINCIPAL_RGB.png";
import "./style.css";
import { Dropdown } from "react-bootstrap";

function Header({ showMobileMenu, setShowMobileMenu }) {
  return (
    <header className="header">
      {showMobileMenu ? <div className="overlay mobile-overlay"></div> : null}
      <div className="container">
        <div className="row align-items-center">
          <div className="left-column col-8 col-sm-8 col-md-4 col-lg-4 d-flex align-items-lg-end">
            <Link to="/">
              <img alt="logo" src={Logo} width="200px" />
            </Link>
            <span className="ml-20">a internet do coração!</span>
          </div>
          <div className="col-4 col-sm-4 col-md-8 col-lg-8">
            <nav
              className={`menu-container d-md-flex justify-content-between justify-content-md-center justify-content-md-end header-menu ${
                showMobileMenu ? "active-mobile-menu" : ""
              }`}
            >
              <div>
                <div className="d-md-none menu-mobile-top row align-items-center">
                  <div className="col-4">
                    <FontAwesomeIcon
                      onClick={() => setShowMobileMenu(!showMobileMenu)}
                      cursor="pointer"
                      fontSize="25px"
                      icon={faTimes}
                      className="c-primary fs-25"
                    />
                  </div>
                  <div className="col-8">
                    <Link to="/">
                      <img alt="logo" src={Logo} width="200px" />
                    </Link>
                  </div>
                </div>
                <ul className="header-menu-items">
                  <li className="position-relative menu-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle className="dropdown-loviz" id="basic">
                        Produtos
                        <FontAwesomeIcon
                          cursor="pointer"
                          fontSize="25px"
                          icon={faChevronDown}
                          className="ml-10 c-primary"
                          size="sm"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="/gigabit-internet" className="p-2">
                          Gigabit Internet
                        </Dropdown.Item>
                        <Dropdown.Item href="/super-wifi" className="p-2">
                          Super Wi-Fi
                        </Dropdown.Item>
                        <Dropdown.Item href="/telefone" className="p-2">
                          Phone Infinity
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li>
                    <NavLink to="/para-residencias">Residências</NavLink>
                  </li>
                  <li className="position-relative menu-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dropdown-loviz"
                        id="company-dropdown"
                      >
                        Empresas
                        <FontAwesomeIcon
                          cursor="pointer"
                          fontSize="25px"
                          icon={faChevronDown}
                          className="ml-10 c-primary"
                          size="sm"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="/para-empresas" className="p-2">
                          Planos Empresariais
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="/para-empresas#phone-plan"
                          className="p-2"
                        >
                          Internet e Telefonia
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="/para-empresas#company-phone"
                          className="p-2"
                        >
                          Telefonia Corporativa
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="/para-empresas#custom-carousel"
                          className="p-2"
                        >
                          Internet Corporativa
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/*<NavLink to="/para-empresas">Empresas</NavLink>*/}
                  </li>
                  <li>
                    <NavLink to="/para-condominios">Condomínios</NavLink>
                  </li>
                  <li className="position-relative menu-dropdown">
                    <Dropdown>
                      <Dropdown.Toggle className="dropdown-loviz" id="basic">
                        Atendimento
                        <FontAwesomeIcon
                          cursor="pointer"
                          fontSize="25px"
                          icon={faChevronDown}
                          className="ml-10 c-primary"
                          size="sm"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="/fale-conosco" className="p-2">
                          Fale conosco
                        </Dropdown.Item>
                        <Dropdown.Item href="/trabalhe-conosco" className="p-2">
                          Trabalhe conosco
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="https://api.whatsapp.com/send?phone=4137977777&text=Assine%20agora"
                          target="_blank"
                          rel="noreferrer"
                          className="p-2"
                        >
                          Assine agora
                        </Dropdown.Item>
                        <Dropdown.Item href="/2-via" className="p-2">
                          2° Via de conta
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="https://api.whatsapp.com/send?phone=4137977777&text=Suporte%20T%C3%A9cnico"
                          target="_blank"
                          rel="noreferrer"
                          className="p-2"
                        >
                          Suporte Técnico
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="https://api.whatsapp.com/send?phone=4137977777&text=LOViZ%20Empresas"
                          target="_blank"
                          rel="noreferrer"
                          className="p-2"
                        >
                          LOViZ Empresas
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="d-flex justify-content-end d-md-none">
              <FontAwesomeIcon
                onClick={() => setShowMobileMenu(!showMobileMenu)}
                cursor="pointer"
                fontSize="25px"
                icon={faBars}
                className="c-primary fs-25"
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
